import React, { useMemo } from 'react'
import { createGlobalStyle } from 'styled-components'

import config from '@/helpers/config'
import { getAbsoluteUrl } from '@/helpers/url'
import ConsoleLogo from '@/components/icons/ConsoleLogo'
import ArrowBack from '@/components/icons/authorization/ArrowBack'

import { BackButton, LogoContainer, StyledLoginLayout } from './styles'

interface LoginLayoutProps {
  children: React.ReactNode
  onBack?: () => void
}

const GlobalStyle = createGlobalStyle<{ background?: string }>`
  body {
    overflow: hidden;
  }

  div[class^="Section__container"] {
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ background }) => (background ? `url(${background})` : `url(/images/signin-background.png)`)};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const LoginLayout: React.FC<LoginLayoutProps> = ({ children, onBack }) => {
  const consoleLogo = useMemo(() => {
    return config.REACT_APP_CUSTOM_CONSOLE_LOGO ? (
      <img src={getAbsoluteUrl(config.REACT_APP_CUSTOM_CONSOLE_LOGO)} alt='logo' />
    ) : (
      <ConsoleLogo />
    )
  }, [])
  const background = useMemo(() => getAbsoluteUrl(config.REACT_APP_CUSTOM_SIGN_IN_BACKGROUND), [])
  return (
    <div className='Section__container'>
      <GlobalStyle background={background} />
      <StyledLoginLayout>
        {onBack && (
          <BackButton onClick={onBack}>
            <ArrowBack />
          </BackButton>
        )}
        <LogoContainer>{consoleLogo}</LogoContainer>
        {children}
      </StyledLoginLayout>
    </div>
  )
}

LoginLayout.defaultProps = {
  onBack: undefined
}

export default LoginLayout
