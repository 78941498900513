import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Formik } from 'formik'

import LargeFieldBlock, { LargeFieldBlockProps } from '@/components/blocks/LargeFieldBlock'
import { FieldConfig, FieldState, getFieldConfig, PreviewFields } from '@/helpers/model/form/fields'
import { getConfigByPathname } from '@/hooks/useCardsHelper'
import { useCognitoUser } from '@/hooks/useCognitoUser'
import LargeFields from '@/pages/cards/FormCard/components/LargeFields'

import { ModelType } from '@/client/schema'
import { PageConfig } from '@/redux/actions/common/ui'
import SmallFields from '@/pages/cards/FormCard/components/SmallFields'
import { SmallFieldsColumns } from '@/pages/cards/FormCard/styles'
import { FORM_VALIDATION_SCHEMA_BY_MODEL_KEY } from '@/helpers/model/form/validation'

import { ClassFieldsCompact, ClassWrapper, Divider } from './styles'

export type ClassValueType = Record<string, any>

export type ClassSelectProps = LargeFieldBlockProps & {
  modelKey?: string
  disabled?: boolean
  state?: FieldState
  value?: ClassValueType
  previewFields?: PreviewFields
  model: ModelType
  parentPage: PageConfig
  list?: boolean
  name?: string

  onOpen?: (item: ClassValueType, expanded: boolean) => void
  onChange?: (values: ClassValueType) => void
}
const ClassSelect: React.FC<ClassSelectProps> = ({
  disabled,
  state: stateConfig,
  value,
  previewFields,
  model,
  parentPage,
  list,
  name,

  onOpen,
  onChange,

  ...fieldBlockProps
}) => {
  const [keyValue, setKeyValue] = useState<string>('_')

  const { hasWriteAccess } = useCognitoUser()

  const { id } = useMemo(() => getConfigByPathname(parentPage.path), [parentPage.path])
  const createMode = useMemo(() => id === 'new', [id])
  const validationSchema = useMemo(() => FORM_VALIDATION_SCHEMA_BY_MODEL_KEY[model.__key], [model.__key])
  const key = useMemo(() => model.inheritors?.__key?.__key, [model])

  const renderField = useCallback(
    (config: FieldConfig) => {
      if (config?.large) {
        return (
          <LargeFields
            key={config?.name}
            modelKey={config?.modelKey ?? ''}
            hasWriteAccess={hasWriteAccess}
            createMode={createMode}
            config={config}
            parentPage={parentPage}
          />
        )
      }
      return <SmallFields key={config?.name} hasWriteAccess={hasWriteAccess} createMode={createMode} config={config} />
    },
    [hasWriteAccess, createMode, parentPage]
  )

  const fields = useMemo(
    () => Object.keys(model.fields).map((selector) => getFieldConfig(model.fields[selector])),
    [model.fields]
  )
  const fieldsInheritors = useMemo(() => {
    const f = model.inheritors?.__refs?.find((r) => r.type === keyValue)?.$ref
    return f
      ? Object.keys((f as ModelType).fields).map((selector) => getFieldConfig((f as ModelType).fields[selector]))
      : []
  }, [keyValue, model.inheritors])

  const handleSubmitForm = useCallback(() => {
    // console.log(values)
  }, [])

  const handleValidate = useCallback(
    (values: any) => {
      if (key && values[key] !== keyValue) {
        setKeyValue(values[key])
      }
      onChange && onChange(values)
    },
    [key, keyValue, onChange]
  )

  useEffect(() => {
    if (value) {
      handleValidate(value)
    }
  }, [value])

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={(value || {}) as any}
      onSubmit={handleSubmitForm}
      validate={handleValidate}
    >
      {() => {
        return (
          <ClassWrapper>
            <LargeFieldBlock {...fieldBlockProps} className='field-block'>
              <ClassFieldsCompact hasLargeFields={false}>
                <SmallFieldsColumns>
                  {fields.map(renderField)}
                  {fieldsInheritors.length > 0 && (
                    <>
                      <Divider />
                      {fieldsInheritors.map(renderField)}
                    </>
                  )}
                </SmallFieldsColumns>
              </ClassFieldsCompact>
            </LargeFieldBlock>
          </ClassWrapper>
        )
      }}
    </Formik>
  )
}

ClassSelect.defaultProps = {
  modelKey: undefined,
  value: undefined,
  disabled: false,
  state: undefined,
  previewFields: undefined,
  list: false,
  name: '',

  onOpen: undefined,
  onChange: undefined
}

export default ClassSelect
