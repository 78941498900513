import { useEffect, useCallback, useRef, useState } from 'react'
import noop from 'lodash/noop'

export type OutsideClickHandlerOptions = {
    calculatePosition?: boolean
    onOpen?: () => void
    onClose?: () => void
}
function useOutsideClickHandler<ContainerType extends HTMLElement, ControlType extends HTMLElement>({
    calculatePosition,
    onOpen,
    onClose
}: OutsideClickHandlerOptions = {}) {
    const containerRef = useRef<ContainerType>()
    const controlRef = useRef<ControlType>()

    const [isOpen, setIsOpen] = useState(false)
    const [initialised, setInitialised] = useState(false)
    const [position, setPosition] = useState({ top: 0, left: 0 })

    const handleOpen = useCallback(
        (event?: React.MouseEvent<ControlType>) => {
            event?.preventDefault()
            event?.stopPropagation()
            setIsOpen(true)
            onOpen?.()
        },
        [onOpen]
    )
    const handleClose = useCallback(() => {
        setIsOpen(false)
        onClose?.()
    }, [onClose])
    const handleToggle = useCallback(
        (event?: React.MouseEvent<ControlType>) => {
            isOpen ? handleClose() : handleOpen(event)
        },
        [isOpen, handleOpen, handleClose]
    )

    const customHandleClickOutside = useCallback(
        (event: Event) => {
            const isNotContent =
                containerRef.current &&
                containerRef.current.contains &&
                !containerRef.current?.contains(event.target as Node)
            const isNotControl = controlRef.current
                ? controlRef.current.contains && !controlRef.current?.contains(event.target as Node)
                : true
            if (isNotContent && isNotControl) {
                handleClose()
            }
        },
        [handleClose, containerRef, controlRef]
    )

    const getContainerPosition = useCallback(() => {
        const containerRect = containerRef?.current?.getBoundingClientRect()
        const controlRect = controlRef?.current?.getBoundingClientRect()

        if (!containerRect || !controlRect) {
            return
        }

        if (controlRect.left > window.innerWidth) {
            handleClose()
        }

        let top = controlRect.top + controlRect.height + 4
        // eslint-disable-next-line prefer-destructuring
        let left = controlRect.left + controlRect.width - containerRect.width

        if (top + containerRect.height > window.innerHeight) {
            top = controlRect.top - containerRect.height - 4
        }

        if (left + containerRect.width > window.innerWidth) {
            left = window.innerWidth - containerRect.width - 4
        }

        setPosition({ top, left })
    }, [containerRef, controlRef, handleClose])

    useEffect(() => {
        if (calculatePosition && isOpen && !initialised) {
            setInitialised(true)
            getContainerPosition()
            window.addEventListener('resize', getContainerPosition)
        }
    }, [calculatePosition, isOpen, initialised, getContainerPosition])

    useEffect(() => {
        if (calculatePosition && !isOpen && initialised) {
            setInitialised(false)
            window.removeEventListener('resize', getContainerPosition)
        }
    }, [calculatePosition, isOpen, initialised, getContainerPosition])

    const root = document.getElementById('root')
    useEffect(() => {
        if (root && isOpen) {
            root.addEventListener('mousedown', customHandleClickOutside)
            return () => {
                root.removeEventListener('mousedown', customHandleClickOutside)
            }
        }
        return noop
    }, [handleClose, customHandleClickOutside, root, isOpen, containerRef])

    return {
        containerRef: containerRef as React.RefObject<ContainerType>,
        controlRef: controlRef as React.RefObject<ControlType>,
        containerStyle: calculatePosition
            ? {
                  ...position,
                  position: 'fixed',
                  zIndex: 1000,
                  opacity: initialised ? 1 : 0,
                  minWidth: controlRef.current?.getBoundingClientRect().width
              }
            : {},
        isOpen,
        onOpen: handleOpen,
        onToggle: handleToggle,
        onClose: handleClose
    }
}

export default useOutsideClickHandler
