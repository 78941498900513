import format from 'date-fns/format'
import objectPath from 'object-path'
import { Field } from '@/client/schema'

export const getFieldValueGetter = (field: Field) => {
    if (field.type === 'Timestamp') {
        return (value: number) => format(new Date(value), 'dd MMM yyyy, HH:mm:ss (z)')
    }

    if (field.type === 'JSON' && field.labelPath) {
        return (value: any) => (value ? objectPath.get(JSON.parse(value), field.labelPath!) : '')
    }

    if (field.type === 'Ref' && field.__ref.type === 'Type') {
        return (value: any) => {
            return value?.id
        }
    }

    return (value: any) => value
}
