import styled from 'styled-components'

import { getSvgColors } from '@/helpers/styles'

export const AccountContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px;

    .text {
        display: flex;
        align-items: center;
        ${({ theme }) => getSvgColors(theme.colors.gray['500'])};
    }
    .description {
        margin-left: 8px;
    }
    .second {
        color: ${({ theme }) => theme.colors.gray['500']};
    }

    img.account-logo {
        max-width: 24px;
        max-height: 24px;
    }
`

export const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 12px;

    .name {
        color: ${({ theme }) => theme.colors.gray['900']};
    }
    .email {
        color: ${({ theme }) => theme.colors.gray['500']};
    }
`
