import React, { useMemo, useState } from 'react'

import { Text } from '@/components/typography/Text'
import { InputProps } from '@/components/controls/Input'
import { InputContainer, InputSC, InputWrapper } from '@/components/controls/Input/styles'

import { InputColorMarker } from './styles'

const Color = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, inputSize, label, placeholder, error, info, value, type, ...props }, ref) => {
    const [localType] = useState(type)

    const backgroundColor = useMemo(() => (value as string) ?? '', [value])

    return (
      <InputWrapper className={['input', className].join(' ')}>
        {label && (
          <Text size='xs' className='label'>
            {label}
          </Text>
        )}
        <InputContainer hasError={!!error}>
          <InputSC
            ref={ref}
            {...props}
            inputSize={inputSize}
            placeholder={placeholder}
            value={value}
            hasValue={!!value}
            type={localType}
          />
          <InputColorMarker backgroundColor={backgroundColor} />
        </InputContainer>
        {error && (
          <Text size='sm' weight='regular' className='error'>
            {error}
          </Text>
        )}
        {info && (
          <Text size='sm' weight='regular' className='info'>
            {info}
          </Text>
        )}
      </InputWrapper>
    )
  }
)

Color.defaultProps = {
  inputSize: 'sm',
  label: undefined,
  error: undefined,
  info: undefined
}

export default Color
