import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import Modal from '@/components/regions/Modal'
import Button from '@/components/controls/Button'

import { useAppDispatch } from '@/redux/store'
import { setModalAction } from '@/redux/actions/common/ui'
import { selectModal } from '@/redux/selectors/common/ui'

import { Buttons, Container, FieldsContainer } from './styles'

const InfoModal: React.FC = () => {
  const dispatch = useAppDispatch()

  const modal = useSelector(selectModal)

  const { data } = modal!
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { info } = data
  const handleCloseModal = useCallback(() => dispatch(setModalAction(undefined)), [dispatch])

  return (
    <Modal closeOnESC>
      <Container>
        <>
          <FieldsContainer>{info}</FieldsContainer>
          <Buttons padding>
            <Button type='button' size='sm' styleType='tertiary' variant='gray' onClick={handleCloseModal}>
              Close
            </Button>
          </Buttons>
        </>
      </Container>
    </Modal>
  )
}

export default InfoModal
