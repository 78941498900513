import React, { useCallback, useState } from 'react'

import { Text } from '@/components/typography/Text'
import ShowPassword from '@/components/icons/authorization/ShowPassword'
import HidePassword from '@/components/icons/authorization/HidePassword'

import Button from '../Button'
import { InputContainer, InputSC, InputWrapper } from './styles'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  inputSize?: 'sm' | 'md'
  label?: string
  error?: string
  info?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, inputSize, label, placeholder, error, info, value, type, ...props }, ref) => {
    const [localType, setLocalType] = useState(type)

    const togglePasswordVisible = useCallback(
      () => setLocalType((currentType) => (currentType === 'password' ? 'text' : 'password')),
      []
    )

    return (
      <InputWrapper className={['input', className].join(' ')}>
        {label && (
          <Text size='xs' className='label'>
            {label}
          </Text>
        )}
        <InputContainer hasError={!!error}>
          <InputSC
            ref={ref}
            {...props}
            inputSize={inputSize}
            placeholder={placeholder}
            value={value}
            hasValue={!!value}
            type={localType}
          />
          {!!value && type === 'password' && (
            <Button
              type='button'
              className='icon'
              size='xs'
              icon='icon'
              styleType='tertiary'
              variant='gray'
              onClick={togglePasswordVisible}
            >
              {localType === 'password' ? <ShowPassword /> : <HidePassword />}
            </Button>
          )}
        </InputContainer>
        {error && (
          <Text size='sm' weight='regular' className='error'>
            {error}
          </Text>
        )}
        {info && (
          <Text size='sm' weight='regular' className='info'>
            {info}
          </Text>
        )}
      </InputWrapper>
    )
  }
)

Input.defaultProps = {
  inputSize: 'sm',
  label: undefined,
  error: undefined,
  info: undefined
}

export default Input
