import { useFormikContext } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import hash from 'object-hash'
import useDebounce from '@/hooks/useDebounce'

const AutoSave = ({ debounceMs }: { debounceMs: number }) => {
  const formik = useFormikContext()
  const [status, setStatus] = useState<string>('')
  const [currentHash, setCurrentHash] = useState<string>('')

  const debouncedFunction = useCallback(() => {
    const valuesHash = hash(formik.values as any)
    if (valuesHash !== currentHash && formik.isValid) {
      formik.submitForm().then(() => {
        setCurrentHash(valuesHash)
        setStatus('Saved to Parent')
      })
    }
  }, [formik, currentHash])

  const debouncedSubmitFunction = useDebounce(debouncedFunction, debounceMs)

  useEffect(() => {
    debouncedSubmitFunction()
  }, [debouncedSubmitFunction, formik.values])

  let result = null

  if (formik.isSubmitting) {
    result = 'Saving...'
  } else if (Object.keys(formik.errors).length > 0) {
    result = `Form Error`
  } else if (status) {
    result = status
  }
  return <>{result}</>
}

export default AutoSave
