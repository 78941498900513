import styled from 'styled-components'

export const DropdownControl = styled.div`
    cursor: pointer;
`

export const DropdownPopup = styled.div<{ fullWidth?: boolean }>`
    min-width: 200px !important;
    max-height: 360px;
    overflow: auto;
    padding: 8px 0;
    background: ${({ theme }) => theme.colors.base.white};
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    border-radius: 8px;
    ${({ theme }) => theme.shadows.regular.lg};

    ${({ fullWidth }) =>
        fullWidth &&
        `
        left: 4px;
        right: 4px;
    `}
`
