import { ImageCellPreviewSize } from './types'

export function getPopupParams(previewSize: ImageCellPreviewSize): string {
    switch (previewSize) {
        case 'Small':
            return `
            max-height: 100px;
        `
        case 'Medium':
            return `
            max-height: 200px;
        `
        case 'Large':
            return `
            max-height: 300px;
        `
        default:
            return `
            max-height: 100px;
        `
    }
}
