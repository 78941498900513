import React, { CSSProperties, PropsWithChildren } from 'react'
import styled from 'styled-components'

const Hidden = styled.div<{ active?: boolean }>`
  ${({ active }) => !active && 'display: none;'}
`

type Props = {
  style?: CSSProperties
  active?: boolean
  activeComponent: React.ReactNode
} & PropsWithChildren
const CardWrapper: React.FC<Props> = ({ active, activeComponent, children, style }) => {
  return (
    <>
      {!active && activeComponent}
      <Hidden active={active} style={style}>
        {children}
      </Hidden>
    </>
  )
}

CardWrapper.defaultProps = {
  active: true,
  style: undefined
}

export default CardWrapper
