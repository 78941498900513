import React, { useCallback } from 'react'
import { IItemProps } from 'react-movable'

import { Text } from '@/components/typography/Text'
import Button from '@/components/controls/Button'
import DataCell from '@/components/controls/Table/components/DataCell'
import { ButtonProps } from '@/components/controls/Button/types'
import ChevronDown from '@/components/icons/menu/ChevronDown'
import Close from '@/components/icons/cardHeader/Close'
import Expand from '@/components/icons/common/Expand'
import LayoutRight from '@/components/icons/common/LayoutRight'

import { FieldState, PreviewFields } from '@/helpers/model/form/fields'
import { TitleConfig } from '@/helpers/model/title'
import { Badge } from '../Badge'
import { BadgeProps } from '../Badge/types'

import {
  Controls,
  PreviewFieldsPopup,
  SearchInput,
  SelectOptionContainer,
  SelectValueContainer,
  SelectValueContent
} from './styles'

export type SelectValueType = 'badge' | 'icon-text' | 'text'
export type SelectTextValue = { name: string }
export type SelectIconTextValue = { name: string; icon: React.ReactNode }
export type SelectBadgeValue = { name: string; color?: string; state?: string }
export type SelectSearch = { query: string; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }
export type SelectValueProps = {
  type?: 'value' | 'option'
  valueType?: SelectValueType
  titleConfig?: TitleConfig
  dndProps?: IItemProps & { isDragged?: boolean }

  active?: boolean
  disabled?: boolean
  controlsOnHover?: boolean

  value?: SelectBadgeValue | SelectTextValue
  state?: FieldState
  search?: SelectSearch
  previewFields?: PreviewFields

  onClick?: (event?: React.MouseEvent<any>) => void
  onOpen?: (expanded: boolean) => void
  onClear?: () => void
}

const DEFAULT_BADGE_PROPS = { styleType: 'light' } as Partial<BadgeProps>

const CONTROL_BUTTON_PROPS = {
  size: 'xs',
  icon: 'icon',
  styleType: 'tertiary',
  variant: 'gray'
} as Partial<ButtonProps>

const SelectValue = React.forwardRef<HTMLDivElement, SelectValueProps>(
  (
    {
      type,
      valueType,
      titleConfig,
      dndProps,

      active,
      disabled,
      controlsOnHover,

      value,
      state: stateConfig,
      search,
      previewFields,

      onClick,
      onOpen,
      onClear
    },
    ref
  ) => {
    const handleClear = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        onClear?.()
      },
      [onClear]
    )
    const handleOpen = useCallback(
      (expanded = false) =>
        (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()
          event.stopPropagation()
          onOpen?.(expanded)
        },
      [onOpen]
    )
    const handleClick = useCallback(
      (event: React.MouseEvent<any>) => {
        event.preventDefault()
        event.stopPropagation()
        onClick?.(event)
      },
      [onClick]
    )

    const renderIconText = useCallback(
      (option: SelectIconTextValue) => (
        <>
          {option.icon}{' '}
          <Text size='sm' className='text-name'>
            {option.name}
          </Text>
        </>
      ),
      []
    )
    const renderText = useCallback(
      (option: SelectTextValue) => {
        const title = titleConfig ? titleConfig.getValue((option as any)[titleConfig.key]) : option.name
        return <Text size='sm'>{title}</Text>
      },
      [titleConfig]
    )
    const renderBadges = useCallback(
      (option: SelectBadgeValue) => {
        const title = titleConfig ? titleConfig.getValue((option as any)[titleConfig.key]) : option.name
        return (
          <>
            <Badge size='sm' {...(option.color ? { background: option.color } : DEFAULT_BADGE_PROPS)}>
              <Text size='sm'>{title}</Text>
            </Badge>
            {option.state && (
              <Badge
                size='sm'
                className='state-badge'
                {...(stateConfig && stateConfig[option.state].color
                  ? { background: stateConfig[option.state].color }
                  : DEFAULT_BADGE_PROPS)}
              >
                <Text size='sm'>{option.state}</Text>
              </Badge>
            )}
          </>
        )
      },
      [stateConfig, titleConfig]
    )

    const renderContent = useCallback(() => {
      if (!value) {
        return null
      }
      if (valueType === 'badge') {
        return renderBadges(value as SelectBadgeValue)
      }
      if (valueType === 'icon-text') {
        return renderIconText(value as SelectIconTextValue)
      }
      if (valueType === 'text') {
        return renderText(value as SelectTextValue)
      }
      return null
    }, [value, valueType, renderBadges, renderIconText, renderText])

    if (type === 'value') {
      let hasControls = !!(onClick || onClear || onOpen)
      if (disabled && !onOpen) {
        hasControls = false
      }
      const chevronClass = ['chevrone-down', 'rotate180', active ? 'opened' : 'closed'].join(' ')
      return (
        <SelectValueContainer controlsOnHover={controlsOnHover}>
          <SelectValueContent
            data-movable-handle
            {...(dndProps || {})}
            role='button'
            ref={ref}
            dragable={!!dndProps}
            valueType={valueType}
            active={active}
            disabled={disabled}
            onClick={!disabled ? handleClick : undefined}
          >
            {renderContent()}
            {search && active && <SearchInput empty={!search.query} autoFocus {...search} />}
          </SelectValueContent>
          {hasControls && (
            <Controls>
              {onOpen && (
                <>
                  <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleOpen(true)}>
                    <Expand />
                  </Button>
                  <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleOpen(false)}>
                    <LayoutRight />
                  </Button>
                </>
              )}
              {!disabled && value && onClear && (
                <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleClear}>
                  <Close />
                </Button>
              )}
              {!disabled && onClick && (
                <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleClick}>
                  <ChevronDown className={chevronClass} />
                </Button>
              )}
            </Controls>
          )}
          {previewFields && !!value && (
            <PreviewFieldsPopup className='preview'>
              {previewFields.map((fields, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className='row'>
                  {fields.map((field) => (
                    <div key={field.name} className='cell'>
                      <DataCell row={value} selector={field.name} imageType='preview' {...field.config} />
                    </div>
                  ))}
                </div>
              ))}
            </PreviewFieldsPopup>
          )}
        </SelectValueContainer>
      )
    }

    return (
      <SelectOptionContainer
        {...(dndProps || {})}
        ref={ref}
        dragable={!!dndProps}
        valueType={valueType}
        active={active}
        onClick={onClick}
      >
        {renderContent()}
      </SelectOptionContainer>
    )
  }
)

SelectValue.defaultProps = {
  type: 'value',
  valueType: 'badge',
  titleConfig: undefined,
  dndProps: undefined,

  active: false,
  disabled: false,
  controlsOnHover: false,

  value: undefined,
  state: undefined,
  search: undefined,
  previewFields: undefined,

  onClick: undefined,
  onOpen: undefined,
  onClear: undefined
}

export default SelectValue
