import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Field, FieldArray, Formik, FormikProps } from 'formik'
import { useAlert } from '@blaumaus/react-alert'
import { arrayMove, List } from 'react-movable'
import objectPath from 'object-path'

import Modal from '@/components/regions/Modal'
import Button from '@/components/controls/Button'
import Select from '@/components/controls/Select'
import Dropdown from '@/components/controls/Dropdown'
import SelectValue from '@/components/blocks/SelectValue'
import Plus from '@/components/icons/cardHeader/Plus'
import Trash from '@/components/icons/fields/Trash'
import Draggable from '@/components/icons/fields/Draggable'
import SmallFields from '@/pages/cards/FormCard/components/SmallFields'
import LargeFields from '@/pages/cards/FormCard/components/LargeFields'

import { useAppDispatch } from '@/redux/store'
import { setModalAction } from '@/redux/actions/common/ui'
import { requestRunActionAction } from '@/redux/actions/actions'
import { selectModal } from '@/redux/selectors/common/ui'

import { FieldConfig } from '@/helpers/model/form/fields'
import {
  Buttons,
  Container,
  DndFieldsRow,
  FieldContainer,
  Fields,
  FieldsContainer,
  FieldsRow,
  Header,
  HeaderLocale,
  SubTitle,
  Title
} from './styles'
import { getModalFormValidation, getModalInitialValues, mapModalField, mapModalFieldsPayload } from './utils'
import { ModalField, ModalPopupButtonField } from './types'

type FieldOptions = {
  form: FormikProps<any>
  prefix?: string
}

const ActionFormModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const alert = useAlert()

  const modal = useSelector(selectModal)

  const [currentLocale, setCurrentLocale] = useState(modal?.data.config?.locale?.defaultLanguage)
  const [currentLanguages, setCurrentLanguages] = useState(modal?.data.config?.locale?.currentLanguages || [])

  const schema = useMemo(() => getModalFormValidation(modal), [modal])
  const initialValues = useMemo(() => getModalInitialValues(modal?.data), [modal])

  const handleAddLanguage = useCallback(
    (locale: string, form: FormikProps<any>) => {
      if (modal?.data.config?.locale) {
        form.setFieldValue('locale', [...currentLanguages, locale].join(','))
      }
      setCurrentLanguages((prev: string[]) => [...prev, locale])
      setCurrentLocale(locale)
    },
    [currentLanguages, modal?.data.config?.locale]
  )
  const handleDeleteLanguage = useCallback(
    (locale: string, form: FormikProps<any>) => {
      if (modal?.data.config?.locale) {
        form.setFieldValue('locale', currentLanguages.filter((l: string) => l !== locale).join(','))
      }
      setCurrentLanguages((prev: string[]) => prev.filter((l) => l !== locale))
      setCurrentLocale('_')
    },
    [currentLanguages, modal?.data.config?.locale]
  )

  const handleCloseModal = useCallback(() => dispatch(setModalAction(undefined)), [dispatch])
  const handleSubmitForm = useCallback(
    async (values: any) => {
      try {
        const { ids, action, entity } = modal?.data.args
        handleCloseModal()
        const payload = mapModalFieldsPayload(modal, values, currentLanguages)
        await dispatch(requestRunActionAction({ modelKey: entity, ids, action, payload })).unwrap()
        alert.show('Action started')
      } catch (e) {
        alert.error((e as any).message)
      }
    },
    [modal, currentLanguages, alert, dispatch, handleCloseModal]
  )

  const renderLocaleBlock = useCallback(
    (form: FormikProps<any>) => {
      const addLocaleOptions = modal?.data.config?.locale?.allLanguages.filter(
        (key: string) => !currentLanguages.includes(key)
      )
      return (
        <HeaderLocale>
          <Field name='locale'>
            {({ field }: any) => <input type='hidden' {...field} value={currentLanguages.join(',')} />}
          </Field>
          <Select
            value={currentLocale}
            options={currentLanguages.map((key: string) => ({ name: key, value: key }))}
            onSelect={setCurrentLocale}
          />
          <Buttons>
            <Button
              type='button'
              icon='icon'
              size='sm'
              styleType='tertiary'
              variant='error'
              disabled={currentLocale === '_'}
              onClick={() => handleDeleteLanguage(currentLocale, form)}
            >
              <Trash />
            </Button>
            <Dropdown
              control={
                <Button
                  type='button'
                  icon='icon'
                  size='sm'
                  styleType='tertiary'
                  disabled={addLocaleOptions.length === 0}
                >
                  <Plus />
                </Button>
              }
              popup={(onCloseMenu) => (
                <div>
                  {addLocaleOptions.map((key: string) => (
                    <SelectValue
                      key={key}
                      type='option'
                      value={{ name: key }}
                      onClick={() => {
                        onCloseMenu()
                        handleAddLanguage(key, form)
                      }}
                    />
                  ))}
                </div>
              )}
            />
          </Buttons>
        </HeaderLocale>
      )
    },
    [modal?.data.config?.locale?.allLanguages, currentLocale, currentLanguages, handleAddLanguage, handleDeleteLanguage]
  )

  const onSelectField = useCallback(
    async (field: ModalField, value: string, options?: FieldOptions) => {
      const configFields = modal?.data.config?.relation[field.id]
      if (!configFields) {
        return
      }

      const allFields = Object.values(configFields).flat() as ModalField[]

      for (let i = 0; i < allFields.length; i += 1) {
        const f = allFields[i]
        const nestedPath = options?.prefix ? `${options?.prefix}.${f.id}` : f.id
        // eslint-disable-next-line no-await-in-loop
        await options?.form.setFieldValue(nestedPath, undefined)
      }

      const valueField = configFields?.[value] || []
      const defaultFields = configFields?.['*'] || []
      const newFields = [...valueField, ...defaultFields]

      for (let i = 0; i < newFields.length; i += 1) {
        const f = newFields[i]
        if (f.default) {
          const nestedPath = options?.prefix ? `${options?.prefix}.${f.id}` : f.id
          // eslint-disable-next-line no-await-in-loop
          await options?.form.setFieldValue(nestedPath, f.default)
        }
      }
    },
    [modal?.data.config?.relation]
  )

  const renderField = useCallback(
    (field: ModalField, options?: FieldOptions) => {
      const config = mapModalField(field)
      if (config && options?.prefix) {
        config.name = `${options?.prefix}.${config.name}`
      }
      if (config && field.localized) {
        config.name = `${config.name}.${currentLocale}`
      }
      return (
        <>
          <SmallFields
            hasWriteAccess
            config={config}
            onSelect={(c: FieldConfig, value: string) => onSelectField(field, value, options)}
          />
          <LargeFields hasWriteAccess modelKey='' config={config} />
        </>
      )
    },
    [currentLocale, onSelectField]
  )

  const renderPopupButton = useCallback(
    (field: ModalPopupButtonField, options?: FieldOptions) => {
      const path = options?.prefix ? `${options?.prefix}.${field.relation}` : field.relation
      const relationValue = objectPath.get(options?.form.values, path)
      const configFields = modal?.data.config.relation[field.relation]

      const valueField = configFields?.[relationValue] || []
      const defaultFields = configFields?.['*'] || []
      const fields = [...valueField, ...defaultFields]

      return (
        <Dropdown
          control={
            <Button className='offset' disabled={!relationValue || fields.length === 0}>
              {field.title}
            </Button>
          }
          popup={<div style={{ padding: '8px' }}>{fields.map((f) => renderField(f, options))}</div>}
        />
      )
    },
    [modal?.data.config?.relation, renderField]
  )

  const renderFormField = useCallback(
    (field: ModalField, options?: FieldOptions) => (
      <FieldContainer key={field.id}>
        {renderField(field, options)}
        {field.type === 'PopupButton' && renderPopupButton(field, options)}
      </FieldContainer>
    ),
    [renderField, renderPopupButton]
  )

  const renderSectionFields = useCallback(
    (section: any, { form }: FieldOptions) => (
      <FieldArray
        key={section.id}
        name={section.id}
        render={(helper) => (
          <List<any>
            lockVertically
            values={form.values[section.id]}
            onChange={({ oldIndex, newIndex }) =>
              form.setFieldValue(section.id, arrayMove(form.values[section.id], oldIndex, newIndex))
            }
            renderList={({ children, props }) => (
              <>
                {section.title && <SubTitle>{section.title}</SubTitle>}
                <Fields {...props} className='small'>
                  {children}
                </Fields>
                <FieldContainer>
                  <Button type='button' icon='left' size='sm' className='plus-button' onClick={() => helper.push({})}>
                    <Plus />
                    Add
                  </Button>
                </FieldContainer>
              </>
            )}
            renderItem={({ isDragged, props, index }) => (
              <DndFieldsRow isDragged={isDragged} {...props} style={{ ...props.style, zIndex: isDragged ? 1001 : 0 }}>
                <FieldContainer small>
                  <Draggable className='icon' />
                </FieldContainer>
                {section.fields.map((fieldRow: ModalField[], rowIndex: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FieldsRow key={rowIndex}>
                    {fieldRow.map((field: ModalField, fieldIndex: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={fieldIndex}>
                        {renderFormField(field, { form, prefix: `${section.id}.${index}` })}
                      </React.Fragment>
                    ))}
                  </FieldsRow>
                ))}
                <FieldContainer small>
                  <Button
                    type='button'
                    className='offset'
                    icon='icon'
                    size='sm'
                    styleType='tertiary'
                    variant='error'
                    onClick={() => helper.remove(index!)}
                  >
                    <Trash />
                  </Button>
                </FieldContainer>
              </DndFieldsRow>
            )}
          />
        )}
      />
    ),
    [renderFormField]
  )

  return (
    <Modal closeOnESC>
      <Container>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
        >
          {(form) => (
            <>
              <Header>
                <Title>{modal?.data.args.action.replaceAll('_', ' ')}</Title>
                {modal?.data.config?.locale && renderLocaleBlock(form)}
              </Header>
              <FieldsContainer>
                <Fields>
                  {modal?.data.fields.map((fieldRow: ModalField[], rowIndex: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <FieldsRow key={`row-${rowIndex}`}>
                      {fieldRow.map((field) => renderFormField(field, { form }))}
                    </FieldsRow>
                  ))}
                </Fields>
                {(modal?.data.sections || []).map((section: any) => renderSectionFields(section, { form }))}
              </FieldsContainer>
              <Buttons padding>
                <Button type='button' size='sm' styleType='tertiary' variant='gray' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  type='button'
                  size='sm'
                  styleType='primary'
                  variant='default'
                  disabled={!form.isValid || form.isSubmitting}
                  onClick={() => form.handleSubmit()}
                >
                  Submit
                </Button>
              </Buttons>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  )
}

export default ActionFormModal
