import React from 'react'
import { useSelector } from 'react-redux'

import { selectModal } from '@/redux/selectors/common/ui'

import InfoModal from '@/modals/InfoModal'
import ActionFormModal from './ActionFormModal'
import DocumentQRCodesModal from './DocumentQRCodesModal'

import { ModalType } from './types'

const Modals: React.FC = () => {
  const modal = useSelector(selectModal)

  if (modal?.type === ModalType.Action) {
    return <ActionFormModal />
  }
  if (modal?.type === ModalType.DocumentQRCodes) {
    return <DocumentQRCodesModal />
  }

  if (modal?.type === ModalType.Info) {
    return <InfoModal />
  }

  return null
}

export default Modals
