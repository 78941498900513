import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import QRCode from 'react-qr-code'

import Modal from '@/components/regions/Modal'
import Button from '@/components/controls/Button'

import { useAppDispatch } from '@/redux/store'
import { setModalAction } from '@/redux/actions/common/ui'
import { selectModal } from '@/redux/selectors/common/ui'

import { Buttons, Container, Title, Header, CodesContainer, Code, SubTitle } from './styles'

const DocumentQRCodesModal: React.FC = () => {
  const dispatch = useAppDispatch()

  const modal = useSelector(selectModal)

  const { data } = modal!
  const handleCloseModal = useCallback(() => dispatch(setModalAction(undefined)), [dispatch])

  const { url, env } = useMemo(() => {
    return data.env !== 'master'
      ? {
          url: `https://gl-lnk.folioapp.me/d/${data?.id}`,
          env: 'Development'
        }
      : {
          url: `https://gl-lnk.folio.id/d/${data?.id}`,
          env: 'Production'
        }
  }, [data])

  return (
    <Modal closeOnESC>
      <Container>
        <>
          <Header>
            <Title>New document</Title>
          </Header>
          <CodesContainer>
            <Code>
              <QRCode value={url} width={150} />
              <SubTitle>{env}</SubTitle>
            </Code>
          </CodesContainer>
          <CodesContainer>
            <SubTitle>ID: {data?.id}</SubTitle>
          </CodesContainer>
          <Buttons padding>
            <Button type='button' size='sm' styleType='tertiary' variant='gray' onClick={handleCloseModal}>
              Close
            </Button>
          </Buttons>
        </>
      </Container>
    </Modal>
  )
}

export default DocumentQRCodesModal
