import { LargeFieldBlockProps } from '@/components/blocks/LargeFieldBlock'
import { FieldState } from '@/helpers/model/form/fields'
import React, { useEffect, useMemo, useState } from 'react'
import config from '@/helpers/config'

export type BundleValue = string
export type BundleProps = LargeFieldBlockProps & {
  path: string
  extension: string
  viewerLink: string

  state?: FieldState
  value?: BundleValue

  disabled?: boolean
}

const PRE_SIGNED_URL = `${config.REACT_APP_MEDIA_UPLOAD_URL}/api/v1/pre-signed-url`

const Bundle: React.FC<BundleProps> = ({ path, extension, value, disabled, state, ...fieldBlockProps }) => {
  const key = useMemo(() => (value ? `${path}/${value}.${extension}` : null), [path, extension, value])

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!key) {
      return
    }
    const fetchData = async () => {
      try {
        const response = await fetch(`${PRE_SIGNED_URL}?key=${key}`)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const result = await response.json()
        setData(result)
        // eslint-disable-next-line no-shadow
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [key])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return <div>{JSON.stringify(data)}</div>
}

Bundle.defaultProps = {
  value: '',
  disabled: false,
  state: {}
}

export default Bundle
