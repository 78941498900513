// eslint-disable-next-line no-shadow
export enum ModalType {
    Action = 'action',
    DocumentQRCodes = 'qrcodes',
    Info = 'info'
}

// eslint-disable-next-line no-shadow
export enum ModalActionType {
    SHOW_DOCUMENT_QR_CODES = 'SHOW_DOCUMENT_QR_CODES',
    ACTION = 'ACTION',
    INFO = 'INFO'
}
