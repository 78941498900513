import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Amplify } from 'aws-amplify'

import Page from '@/components/layouts/Page'
import CardsPage from '@/pages/cards/CardsPage'
import UnauthenticatedRoute from '@/hocs/UnauthenticatedRoute'
import PrivateRoute from '@/hocs/PrivateRoute'
import { NavigateToCard } from '@/hocs/NavigateToCard'

import { amplifyConfig } from '@/cognito/client'
import { AUTH_ROUTES } from '@/pages/auth'
import { HOME_URL } from '@/constants/routes'
import { MENU_CONFIG } from '@/helpers/model/menu'
import config from '@/helpers/config'
import { getAbsoluteUrl } from '@/helpers/url'

Amplify.configure(amplifyConfig)

const App: React.FC = () => {
  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (link && config.REACT_APP_CUSTOM_FAVICON) {
      link.href = getAbsoluteUrl(config.REACT_APP_CUSTOM_FAVICON)
    }
  }, [])
  return (
    <Routes>
      <Route path={HOME_URL} element={<Page />}>
        <Route index element={<NavigateToCard defaultPath={MENU_CONFIG[0].config.options[0].path} />} />
        {MENU_CONFIG.map(({ config: { path, options } }) => (
          <Route key={path} path={path}>
            <Route index element={<NavigateToCard defaultPath={options[0].path} />} />
            <Route
              path=':modelKey/*'
              element={
                <PrivateRoute>
                  <CardsPage />
                </PrivateRoute>
              }
            />
          </Route>
        ))}
      </Route>
      {AUTH_ROUTES.map(({ path, element }) => (
        <Route key={path} path={path} element={<UnauthenticatedRoute>{element}</UnauthenticatedRoute>} />
      ))}
      <Route path='*' element={<NavigateToCard defaultPath={MENU_CONFIG[0].config.options[0].path} />} />
    </Routes>
  )
}
export default App
