import React from 'react'

import { Text } from '@/components/typography/Text'

import { ImageContainer } from './styles'
import { ImageCellPreviewSize, ImageCellType } from './types'

type ImageCellProps = {
  value: string | string[] | undefined
  previewSize: ImageCellPreviewSize
  imageType: ImageCellType
  getUrl?: (id: string) => string | undefined
}
const ImageCell: React.FC<ImageCellProps> = ({ value, previewSize, imageType, getUrl }) => {
  if (!value) {
    return null
  }
  if (!getUrl) {
    return <Text size='xs'>{value}</Text>
  }
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((item) => (
          <ImageContainer key={item} previewSize={previewSize} isPreview={imageType === 'preview'}>
            <img className='image' loading='lazy' src={getUrl(item)} alt='' />
            {imageType === 'table' && <img className='popup' loading='lazy' src={getUrl(item)} alt='' />}
          </ImageContainer>
        ))}
      </>
    )
  }
  return (
    <ImageContainer previewSize={previewSize} isPreview={imageType === 'preview'}>
      <img className='image' loading='lazy' src={getUrl(value)} alt='' />
      {imageType === 'table' && <img className='popup' loading='lazy' src={getUrl(value)} alt='' />}
    </ImageContainer>
  )
}

ImageCell.defaultProps = { getUrl: undefined }

export default ImageCell
