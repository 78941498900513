import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { selectSelf } from '.'
import { SubformReducerState } from '../reducers/subform'

export const selectSubformState = createSelector(selectSelf, (state: RootState) => state.subform)
export const selectSubformValue = (key: string) =>
    createSelector(selectSubformState, (state: SubformReducerState) => state[key])
export const selectSubformData = (key: string) =>
    createSelector(selectSubformState, (state: SubformReducerState) => state[key]?.data)
