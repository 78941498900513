import styled, { Attrs } from 'styled-components'
import { hexToRgb } from '@/helpers/hexToRgb'

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

type PaginationButtonProps = { disabled?: boolean; active?: boolean } & Attrs
export const PaginationButton = styled.div.attrs(({ disabled, active }: PaginationButtonProps) => ({
    className: [disabled ? 'disabled' : null, active ? 'active' : null].join(' ')
}))<PaginationButtonProps>`
    color: ${({ theme }) => theme.colors.gray['500']};
    border-radius: 6px;
    padding: 4px 10px;
    transition: all 100ms linear;
    ${({ theme }) => theme.typography.text.size.sm}
    ${({ theme }) => theme.typography.text.weight.medium}
    ${({ theme, onClick }) =>
        onClick &&
        `
        cursor: pointer;
        &:hover {
            background: ${hexToRgb(theme.colors.base.black, 0.1)};
        }
    `}

    &.active {
        background: ${({ theme }) => hexToRgb(theme.colors.base.black, 0.1)};
    }
    &:disabled,
    &.disabled {
        cursor: not-allowed;
        background: transparent;
        color: ${({ theme }) => theme.colors.gray['300']};
    }
`
