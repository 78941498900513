import styled from 'styled-components'

export const Values = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 8px;
`

export const ControlsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ControlsMultilineRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 8px;
`
