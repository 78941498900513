import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import ObjectSelect, { ObjectSelectProps, ObjectValueType } from '@/components/controls/ObjectSelect'

interface ObjectFieldProps extends ObjectSelectProps {
  name: string
}

const ObjectField: React.FC<ObjectFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<ObjectValueType[]>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name)
      const onChange = (values: ObjectValueType[]) => {
        const valueToSet = props.list ? values : values?.[0]
        form.setFieldValue(field.name, valueToSet)
      }

      return (
        <ObjectSelect {...field} {...props} error={error} disabled={isSubmitting || disabled} onChange={onChange} />
      )
    }}
  </Field>
)

export default ObjectField
