import { TableColumn } from '@/components/controls/Table'
import { DataCellValues } from '@/components/controls/Table/components/DataCell'
import { schema } from '@/helpers/schema'
import { getImageUrl } from '@/helpers/image'
import { Field, ModelEnum, ModelType } from '@/client/schema'
import { getValueType } from '../valueType'

type GetDataCellValues = (field: Field) => DataCellValues
export const getDataCellValues: GetDataCellValues = (field: Field) => {
    if (
        field.type === 'String' ||
        field.type === 'Markdown' ||
        field.type === 'Int' ||
        field.type === 'Float' ||
        field.type === 'ID' ||
        field.type === 'IPAddress' ||
        field.type === 'Phone' ||
        field.type === 'Email'
    ) {
        return { type: 'string' }
    }
    if (field.type === 'JSON') {
        return field.labelPath ? { type: 'json', labelPath: field.labelPath } : { type: 'string' }
    }
    if (field.type === 'Object') {
        return { type: 'object' }
    }
    if (field.type === 'Class') {
        return { type: 'class', modelKey: field.__ref?.__key }
    }

    if (field.type === 'URL') {
        return { type: 'link' }
    }
    if (field.type === 'Timestamp') {
        return { type: 'timestamp' }
    }
    if (field.type === 'List' && field.__kind === 'ArrayLikeOfScalar') {
        const fieldRef = field.of
        const refConfig = getDataCellValues(fieldRef as Field)
        return { type: 'list', config: refConfig }
    }
    if (field.type === 'List' && field.__kind === 'ArrayLikeOfObject') {
        const fieldRef = field.of
        const refConfig = getDataCellValues(fieldRef as Field)
        return { type: 'list', config: refConfig }
    }
    if (field.type === 'Img') {
        const { path, size, extension, pathWithoutSize, previewSize } = field
        const getUrl = (id: string) => getImageUrl(id, path, extension, size, pathWithoutSize)
        return { type: 'image', previewSize, getUrl }
    }
    if (field.type === 'Boolean') {
        return { type: 'boolean' }
    }
    if (field.type === 'List' || field.type === 'Set' || field.type === 'Formula') {
        const listItemType = field.of
        return getDataCellValues(listItemType as Field)
    }
    if (field.type === 'Ref') {
        if (field.__kind === 'RefEnum') {
            const fieldEnum = (field.__ref as ModelEnum).members
            const colors = Object.keys(fieldEnum).reduce(
                (accumulator, item) => ({
                    ...accumulator,
                    [item]: fieldEnum[item].color
                }),
                {}
            )
            return { type: 'relation', relationFormat: 'enum', colors }
        }
        if (field.__kind === 'RefType') {
            const { __key: modelKey } = field.__ref as ModelType
            return { type: 'relation', modelKey, relationFormat: 'type' }
        }
        return { type: undefined }
    }
    return { type: undefined }
}

const getTableColumnsByModelKey = (modelKey: string) => {
    const model = schema.models[modelKey] as ModelType
    return Object.keys(model.fields).reduce((accumulator, key) => {
        if (model.fields[key].visible === false) {
            return accumulator
        }
        const field = model.fields[key]
        const dataCell = getDataCellValues(field)
        if (!dataCell.type) {
            return accumulator
        }
        return [
            ...accumulator,
            {
                key,
                dataCell,
                selector: field.__key,
                type: getValueType(field)
            } as TableColumn
        ]
    }, [] as TableColumn[])
}

export const TABLE_COLUMNS_BY_MODEL_KEY: Record<string, TableColumn[]> = Object.keys(schema.models).reduce(
    (accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if (model.type === 'Type' || model.type === 'ObjectType' || model.type === 'Class') {
            return {
                ...accumulator,
                [modelKey]: getTableColumnsByModelKey(modelKey)
            }
        }
        return accumulator
    },
    {}
)
