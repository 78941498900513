import styled from 'styled-components'

import { getSvgColors } from '@/helpers/styles'
import { SelectValueType } from '.'

export const Controls = styled.div.attrs(() => ({ className: 'controls' }))`
    position: absolute;
    right: 1px;
    top: 1px;
    display: flex;
    align-items: center;
    height: auto;
    padding: 7px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.base.white};
    transition: all 100ms linear;

    ${({ theme }) => getSvgColors(theme.colors.gray['400'], '.chevrone-down')};
`

export const SelectOptionContainer = styled.div<{
    active?: boolean
    dragable?: boolean
    isDragged?: boolean
    valueType?: SelectValueType
}>`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.base.white};
    padding: 8px 12px;
    transition: all linear 100ms;

    .state-badge {
        margin-left: 4px;
    }

    &:hover {
        background: ${({ theme }) => theme.colors.gray['100']};
    }

    ${({ dragable }) => dragable && `cursor: grab;`}
    ${({ isDragged }) => isDragged && `cursor: grabbing;`}
    
    ${({ theme, active }) =>
        active &&
        `
        background: ${theme.colors.gray['100']};
    `}
    ${({ onClick }) =>
        onClick &&
        `
        cursor: pointer;
    `}
`
export const SelectValueContainer = styled.div<{
    controlsOnHover?: boolean
}>`
    position: relative;

    .preview {
        display: none;
    }
    &:hover {
        .preview {
            display: flex;
        }
    }

    ${({ controlsOnHover }) =>
        controlsOnHover &&
        `
        .controls {
            display: none;
        }
        &:hover {
            .controls {
                display: flex;
            }
        }
    `}
`
export const SelectValueContent = styled.div<{
    active?: boolean
    disabled?: boolean
    dragable?: boolean
    isDragged?: boolean
    valueType?: SelectValueType
}>`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: auto;
    padding: 8px 12px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.base.white};
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    transition: all linear 100ms;

    .badge,
    .badge .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .state-badge {
        margin-left: 4px;
    }

    ${({ dragable }) => dragable && `cursor: grab;`}
    ${({ isDragged }) => isDragged && `cursor: grabbing;`}
    
    ${({ theme, onClick }) =>
        onClick &&
        `
        cursor: pointer;
        &:hover {
            border-color: ${theme.colors.primary['300']};
        }
    `}
    ${({ theme, active }) =>
        active &&
        `
        border-color: ${theme.colors.primary['300']};
        box-shadow: 0px 0px 0px 2px ${theme.colors.primary['100']};
    `}
    ${({ theme, disabled }) =>
        disabled &&
        `
        outline: none;
        pointer-events: none;
        color: ${theme.colors.gray['400']};
        background: ${theme.colors.gray['100']};
        border-color: ${theme.colors.gray['300']};
    `}
`

export const ImagePreviewContainer = styled.img`
    height: 50px;
    min-width: 50px;
    max-width: 100px;
    transition: all linear 150ms;

    &.expanded {
        width: 100%;
        height: auto;
        max-width: 300px;
        max-height: 300px;
    }
`
