import styled from 'styled-components'
import { Form } from 'formik'

export const StyledForm = styled(Form)`
    height: 100%;
`
export const FormWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    height: calc(100% - 44px);
    padding: 4px 24px 12px;
    margin: 0 auto;
    overflow: auto;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`
export const LargeFieldsColumn = styled(Column)`
    gap: 16px;
    padding: 0px 12px 12px;
`
export const SmallFieldsColumns = styled(Column)`
    gap: 24px;
    padding: 8px 12px;
`
export const Fields = styled.div<{ hasLargeFields?: boolean }>`
    display: flex;
    padding-bottom: 110px;
    ${SmallFieldsColumns} {
        width: 100%;
    }
    ${({ hasLargeFields }) =>
        hasLargeFields &&
        `
        ${LargeFieldsColumn} {
            width: 60%;
        }
        ${SmallFieldsColumns} {
            width: 40%;
        }
    `}
`

export const FieldsCompact = styled(Fields)`
    padding-bottom: 20px;
    border-color: ${({ theme }) => theme.colors.gray['100']};
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;

    ${SmallFieldsColumns} {
        width: 100%;
        gap: 16px;
    }
    ${LargeFieldsColumn} {
        width: 100%;
    }
`
