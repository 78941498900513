import React from 'react'
import { FieldArray } from 'formik'

import { arrayMove, List } from 'react-movable'
import { DndFieldsRow, FieldContainer, Fields } from '@/modals/ActionFormModal/styles'
import Button from '@/components/controls/Button'
import Plus from '@/components/icons/cardHeader/Plus'
import Draggable from '@/components/icons/fields/Draggable'
import Trash from '@/components/icons/fields/Trash'
import { Text } from '@/components/typography/Text'
import InputField from '@/components/fields/InputField'
import { InputProps } from '../../controls/Input'

const InputFieldArray: React.FC<InputProps> = ({ label, disabled, type, ...props }) => (
  <FieldArray name={props.name || ''}>
    {({ form, name, ...helper }) => (
      <List<any>
        lockVertically
        values={form.values[name] || []}
        onChange={({ oldIndex, newIndex }) =>
          form.setFieldValue(name, arrayMove(form.values[name], oldIndex, newIndex))
        }
        renderList={({ children, props: listProps }) => (
          <>
            {label && (
              <Text size='xs' className='label'>
                {label}
              </Text>
            )}
            <Fields {...listProps}>{children}</Fields>
            <FieldContainer>
              {!disabled && (
                <Button type='button' icon='left' size='sm' onClick={() => helper.push(undefined)}>
                  <Plus />
                  Add
                </Button>
              )}
            </FieldContainer>
          </>
        )}
        renderItem={({ isDragged, props: itemProps, index }) => (
          <DndFieldsRow
            isDragged={isDragged}
            {...itemProps}
            style={{ ...itemProps.style, zIndex: isDragged ? 1001 : 0 }}
          >
            {!disabled && (
              <FieldContainer small>
                <Draggable className='icon' />
              </FieldContainer>
            )}
            <InputField
              name={`${name}.${index}`}
              type={type}
              disabled={disabled}
              placeholder={`Enter ${type || 'value'}...`}
            />
            {!disabled && (
              <FieldContainer small>
                <Button
                  type='button'
                  className='offset'
                  icon='icon'
                  size='sm'
                  styleType='tertiary'
                  variant='error'
                  onClick={() => helper.remove(index!)}
                >
                  <Trash />
                </Button>
              </FieldContainer>
            )}
          </DndFieldsRow>
        )}
      />
    )}
  </FieldArray>
)

export default InputFieldArray
