import React, { useCallback, useEffect } from 'react'

import Tooltip from '@/components/blocks/Tooltip'
import Button from '@/components/controls/Button'
import Close from '@/components/icons/cardHeader/Close'

import { useAppDispatch } from '@/redux/store'
import { setModalAction } from '@/redux/actions/common/ui'

import { ModalContainer, ModalWrapper } from './styles'

type Props = { className?: string; closeOnESC?: boolean; onClose?: () => void; children: React.ReactNode }
const Modal: React.FC<Props> = ({ className, closeOnESC, onClose, children }) => {
  const dispatch = useAppDispatch()

  const handleCloseOnESC = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && closeOnESC) {
        dispatch(setModalAction(undefined))
      }
    },
    [dispatch, closeOnESC]
  )

  useEffect(() => {
    if (closeOnESC) {
      document.addEventListener('keydown', handleCloseOnESC)
    }
    return () => {
      document.removeEventListener('keydown', handleCloseOnESC)
    }
  }, [handleCloseOnESC, closeOnESC])

  return (
    <ModalWrapper>
      <ModalContainer className={className}>
        {children}
        {onClose && (
          <Tooltip position='bottom' arrowPosition='right' description='Close modal' className='close-button'>
            <Button type='button' icon='icon' size='xs' variant='gray' onClick={onClose}>
              <Close />
            </Button>
          </Tooltip>
        )}
      </ModalContainer>
    </ModalWrapper>
  )
}

Modal.defaultProps = {
  className: undefined,
  onClose: undefined,
  closeOnESC: undefined
}

export default Modal
