import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAlert } from '@blaumaus/react-alert'

import DropdownMenuList, { DropdownMenuOption } from '@/components/blocks/DropdownMenuList'
import { ACTIONS_BY_MODEL_KEY } from '@/helpers/model/table/actions'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import { useAppDispatch } from '@/redux/store'
import { PageConfig } from '@/redux/actions/common/ui'
import { requestDeleteEntityAction } from '@/redux/actions/form'
import { requestRunActionAction } from '@/redux/actions/actions'
import { requestTableDataAction } from '@/redux/actions/table'
import { selectTablePagination } from '@/redux/selectors/table'
import { selectForm } from '@/redux/selectors/form'

type Props = {
  disableActions: boolean
  page: PageConfig
  onCloseMenu: () => void
  showDelete: boolean
  showAdditionalActions: boolean
}
const FormDropdownMenu: React.FC<Props> = ({
  disableActions,
  page,
  onCloseMenu,
  showDelete,
  showAdditionalActions
}) => {
  const alert = useAlert()
  const dispatch = useAppDispatch()
  const { hasWriteAccess } = useCognitoUser()
  const { onClose, onCloseOther } = useCardsHelper()

  const { modelKey, id } = useMemo(() => getConfigByPathname(page.path), [page.path])
  const { pageIndex } = useSelector(selectTablePagination(modelKey))
  const { data } = useSelector(selectForm(id!))

  const handleRunAction = useCallback(
    (action?: string) => async () => {
      try {
        if (!action || !id) return
        await dispatch(requestRunActionAction({ modelKey, action, ids: [id] })).unwrap()
        onCloseMenu()
        alert.show('Action started')
      } catch (e) {
        alert.error((e as any).message)
      }
    },
    [dispatch, modelKey, id, onCloseMenu, alert]
  )
  const handleCopyID = useCallback(() => {
    copyToClipboard(id || '')
    alert.show('Copied to clipboard')
  }, [id, alert])
  const handleClose = useCallback(() => {
    onClose(page.path)
    onCloseMenu()
  }, [page.path, onClose, onCloseMenu])
  const handleCloseOther = useCallback(() => {
    onCloseOther(page.path, 'form')
    onCloseMenu()
  }, [page.path, onCloseOther, onCloseMenu])
  const handleDelete = useCallback(async () => {
    onClose(page.path)
    await dispatch(requestDeleteEntityAction({ modelKey, id: id! })).unwrap()
    dispatch(requestTableDataAction({ modelKey, pageIndex }))
    alert.show('Deleted')
  }, [id, modelKey, pageIndex, page.path, alert, dispatch, onClose])

  const actionOptions = useMemo(() => {
    const actions = ACTIONS_BY_MODEL_KEY[modelKey]
    if (!actions || actions.length === 0 || id === 'new') {
      return []
    }
    const allowedActions = actions.filter(({ filter }) => {
      if (!filter) {
        return true
      }
      const fields = Object.keys(filter)
      return fields.every((field) => filter[field].includes(data[field]))
    })
    const groupActions = allowedActions.reduce((accumulator, { action, group, type }) => {
      const actionConfig = {
        type,
        title: action.split('_').join(' '),
        onClick: handleRunAction(action)
      } as DropdownMenuOption
      if (group) {
        return {
          ...accumulator,
          [group]: [...(accumulator[group] || []), actionConfig]
        }
      }
      return {
        ...accumulator,
        default: [...(accumulator.default || []), actionConfig]
      }
    }, {} as Record<string, DropdownMenuOption[]>)
    return Object.keys(groupActions).map((key) => groupActions[key])
  }, [modelKey, id, data, handleRunAction])

  const items: DropdownMenuOption[][] = useMemo(
    () => [
      ...(hasWriteAccess && !disableActions && actionOptions.length > 0 ? actionOptions : []),
      // ...(hasWriteAccess ? ([[{ title: 'Duplicate' }]] as DropdownMenuOption[][]) : []),
      showAdditionalActions
        ? [
            ...(id ? [{ title: 'Copy ID', onClick: handleCopyID }] : []),
            { title: 'Close panel', onClick: handleClose },
            { title: 'Close other panels', onClick: handleCloseOther }
          ]
        : [],
      ...(hasWriteAccess && showDelete
        ? ([[{ title: 'Delete', type: 'delete', onClick: handleDelete }]] as DropdownMenuOption[][])
        : [])
    ],
    [
      hasWriteAccess,
      disableActions,
      actionOptions,
      showAdditionalActions,
      showDelete,
      id,
      handleCopyID,
      handleClose,
      handleCloseOther,
      handleDelete
    ]
  )
  return <DropdownMenuList items={items} />
}

export default FormDropdownMenu
