import styled from 'styled-components'

import { loadingAnimation } from '@/constants/styles/animations'
import { getPopupParams } from './utils'
import { ImageCellPreviewSize } from './types'

export const ImageContainer = styled.div<{
    previewSize: ImageCellPreviewSize
    isPreview: boolean
}>`
    display: flex;
    .image {
        height: 28px;
        min-width: 28px;
        border-radius: 4px;
        background: ${({ theme }) =>
            `linear-gradient(90deg, ${theme.colors.gray['200']}, ${theme.colors.gray['300']})`};
        background-size: 200% 200%;
        animation: ${loadingAnimation} 1s ease infinite;

        ${({ isPreview }) => isPreview && 'height: 100px;'}
    }
    .popup {
        display: none;
        position: absolute;
        top: calc(100% + 4px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        max-height: 100px;
        border-radius: 8px;
        transition: all linear 100ms;
        ${({ theme }) => theme.shadows.tight.lg};
        ${({ previewSize }) => getPopupParams(previewSize)}
    }

    &:hover .popup {
        display: flex;
    }
`
