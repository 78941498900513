import { getParams, splitHash } from '@/helpers/url'

export const getId = (prefix = ''): string => {
    const millis = new Date().valueOf()
    return `${prefix}${millis.toString(36)}`
}

export const getDefaultId = (): string => {
    return Math.random().toString(36).substring(2, 10)
}

export const getTitle = (hash: string): string => {
    const parts = splitHash(hash)
    return [parts.form, ...parts.subforms]
        .map((part) => getParams(part).fieldName)
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' \\ ')
}
