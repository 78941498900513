import React, { useCallback, useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'

import { Text } from '@/components/typography/Text'
import SelectValue from '@/components/blocks/SelectValue'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import { useAsyncOptions } from '@/hooks/useAsyncOptions'
import { FieldState, PreviewFields } from '@/helpers/model/form/fields'
import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'

import { SelectContainer, SelectPopup, SelectValueContainer } from '../Select/styles'
import Spinner from '../Spinner'

export type AsyncSelectValue = { id: string; name: string; state?: string }
export type AsyncSelectProps = {
  modelKey?: string
  excludedOptions?: string[]

  className?: string
  value?: AsyncSelectValue
  label?: string
  error?: string
  info?: string
  state?: FieldState
  disabled?: boolean
  requestOnMount?: boolean
  previewFields?: PreviewFields

  onSelect?: (value: AsyncSelectValue) => void
  onOpen?: (id: string, expanded: boolean) => void
  onClear?: () => void
  onClose?: () => void
}

const AsyncSelect: React.FC<AsyncSelectProps> = ({
  modelKey,
  excludedOptions,

  className,
  label,
  value,
  error,
  info,
  state: stateConfig,
  disabled,
  requestOnMount,
  previewFields,

  onSelect,
  onOpen: onOpenEntity,
  onClear,
  onClose: onCloseCallback
}) => {
  const { loading, noData, query, onChangeQuery, options, onRequestOptions } = useAsyncOptions(
    modelKey,
    excludedOptions
  )

  const { isOpen, containerRef, controlRef, containerStyle, onToggle, onOpen, onClose } = useOutsideClickHandler<
    HTMLDivElement,
    HTMLDivElement
  >({
    calculatePosition: true,
    onOpen: onRequestOptions,
    onClose: onCloseCallback
  })

  const titleConfig = useMemo(() => TITLE_GETTER_BY_MODEL_KEY[modelKey!], [modelKey])

  useEffect(() => {
    if (!disabled && !isOpen && requestOnMount && noData) {
      onToggle()
    }
  }, [disabled, isOpen, requestOnMount, noData, onRequestOptions, onToggle])

  const handleSelect = useCallback(
    (newValue: AsyncSelectValue) => () => {
      onClose()
      onSelect?.(newValue)
    },
    [onClose, onSelect]
  )

  const handleOpen = useCallback((id: string) => (expanded: boolean) => onOpenEntity?.(id, expanded), [onOpenEntity])

  const renderPopupContent = useCallback(() => {
    if (loading) {
      return <Spinner />
    }
    if (noData) {
      return (
        <Text size='sm' className='no-data'>
          Nothing found
        </Text>
      )
    }
    return (options || []).map((option) => (
      <SelectValue
        key={option.id}
        type='option'
        titleConfig={titleConfig}
        active={value?.id === option.id}
        value={option}
        state={stateConfig}
        onClick={handleSelect(option)}
      />
    ))
  }, [loading, noData, options, stateConfig, titleConfig, value, handleSelect])

  return (
    <SelectContainer className={['async-select', className].join(' ')}>
      <SelectValueContainer ref={controlRef}>
        {label && (
          <Text size='xs' className='label'>
            {label}
          </Text>
        )}
        <SelectValue
          titleConfig={titleConfig}
          active={isOpen}
          disabled={disabled}
          controlsOnHover
          value={value}
          state={stateConfig}
          search={{ query, onChange: onChangeQuery }}
          previewFields={previewFields}
          onOpen={value ? handleOpen(value.id) : undefined}
          onClick={onOpen}
          onClear={onClear}
        />
        {error && (
          <Text size='sm' weight='regular' className='error'>
            {error}
          </Text>
        )}
        {info && (
          <Text size='sm' weight='regular' className='info'>
            {info}
          </Text>
        )}
      </SelectValueContainer>
      <>
        {!!isOpen &&
          ReactDOM.createPortal(
            <SelectPopup ref={containerRef} style={containerStyle}>
              {renderPopupContent()}
            </SelectPopup>,
            document.body
          )}
      </>
    </SelectContainer>
  )
}

AsyncSelect.defaultProps = {
  modelKey: undefined,
  excludedOptions: undefined,

  className: undefined,
  value: undefined,
  label: undefined,
  error: undefined,
  info: undefined,
  state: undefined,
  disabled: false,
  requestOnMount: false,
  previewFields: undefined,

  onSelect: undefined,
  onOpen: undefined,
  onClear: undefined,
  onClose: undefined
}

export default AsyncSelect
