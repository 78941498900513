import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useAlert } from '@blaumaus/react-alert'

import { Card, CardConfig, CardTitle } from '@/components/regions/Card'
import CollapsedCard from '@/components/regions/CollapsedCard'
import { Display } from '@/components/typography/Display'
import { TextPlaceholder } from '@/components/blocks/TextPlaceholder'
import Spinner from '@/components/controls/Spinner'
import { AutoSave, CardWrapper } from '@/components/regions'

import { FORM_INITIAL_VALUES_BY_MODEL_KEY } from '@/helpers/model/form/initialValues'
import { FORM_VALIDATION_SCHEMA_BY_MODEL_KEY } from '@/helpers/model/form/validation'
import { FieldConfig, SUBFORM_FIELDS_BY_MODEL_KEY } from '@/helpers/model/form/fields'
import { FilterOption } from '@/helpers/model/table/filterAndSort'
import { getPathByModelKey } from '@/helpers/model/menu'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import { useAppDispatch } from '@/redux/store'
import { PageConfig } from '@/redux/actions/common/ui'
import { setFilterTableAction } from '@/redux/actions/table'
import { selectForm } from '@/redux/selectors/form'
import { selectSubformData } from '@/redux/selectors/subform'
import { updateSubformAction } from '@/redux/actions/subform'
import { getFieldDataPath, getHashParams, getParentHash, splitHash } from '@/helpers/url'
import { getId, getTitle } from '@/helpers/subforms'

import FormCardHeader from '../FormCard/components/FormCardHeader'
import SmallFields from '../FormCard/components/SmallFields'
import LargeFields from '../FormCard/components/LargeFields'
import { Fields, FormWrapper, LargeFieldsColumn, SmallFieldsColumns, StyledForm } from './styles'

export type SubformCardProps = { page: PageConfig; card: CardConfig }
const SubformCard: React.FC<SubformCardProps> = ({ page, card }) => {
  const alert = useAlert()
  const dispatch = useAppDispatch()
  const { hasWriteAccess } = useCognitoUser()
  const { onOpenForm, onOpenIndex, onClose } = useCardsHelper()

  const hashes = splitHash(page.path)
  const subformHash = hashes.subforms[hashes.subforms.length - 1]
  const [, modelKey, , index] = subformHash.split('/')
  const { id, path, model } = useMemo(() => getConfigByPathname(hashes.form), [hashes.form])

  const { data, loading, called } = useSelector(selectForm(id!))
  const subformData = useSelector(selectSubformData(getParentHash(page.path)))
  const fieldData = useMemo(() => {
    if (subformData) {
      return subformData
    }
    if (data) {
      const fieldDataPath = getFieldDataPath(page.path)
      let subformDataInner: any = data
      fieldDataPath.forEach((fdp) => {
        if (subformDataInner) {
          const subObject: any = subformDataInner?.[fdp.fieldName]
          subformDataInner = Array.isArray(subObject) ? subObject.find((so) => so.__id === fdp.id) : subObject
        }
      })
      return subformDataInner
    }
    return null
  }, [data, page.path, subformData])
  const dataId = useMemo(() => (fieldData?.__id === 'new' ? getId() : fieldData?.__id), [fieldData?.__id])

  const createMode = useMemo(() => index === 'new', [index])

  const initialValues = useMemo(() => FORM_INITIAL_VALUES_BY_MODEL_KEY[modelKey], [modelKey])
  const validationSchema = useMemo(() => FORM_VALIDATION_SCHEMA_BY_MODEL_KEY[modelKey], [modelKey])
  const { large, small } = useMemo(() => SUBFORM_FIELDS_BY_MODEL_KEY[modelKey], [modelKey])
  const hasLargeFields = useMemo(() => large.length > 0, [large])
  const hashParams = useMemo(() => getHashParams(page.path), [page.path])
  const title = useMemo(() => getTitle(page.path), [page.path])

  const pageTitle = useMemo(() => {
    if (createMode) {
      return 'Create'
    }
    if (loading || !called || data === null) {
      return <TextPlaceholder />
    }
    return hashParams?.fieldName.toUpperCase()
  }, [createMode, data, loading, called, hashParams])

  useEffect(() => {
    if (called && !loading && data === null) {
      onClose(page.path)
      alert.error(`${model.title} not found`)
    }
  }, [called, loading, data, model, page.path, alert, onClose])

  const handleSubmitForm = useCallback(
    async (values: any) => {
      dispatch(
        updateSubformAction({
          key: getParentHash(page.path),
          values: {
            ...values,
            __id: dataId
          }
        })
      )
    },
    [page.path, dispatch, dataId]
  )

  const handleOpenForm = useCallback(
    (formModelKey: string, formId: string, expanded: boolean) => {
      const modelPath = getPathByModelKey(formModelKey)
      onOpenForm(`${modelPath}/${formId}`, expanded, page.path)
    },
    [page.path, onOpenForm]
  )
  const handleOpenIndexTable = useCallback(
    (indexModelKey: string, filter: FilterOption) => {
      dispatch(
        setFilterTableAction({
          modelKey: indexModelKey,
          data: { key: filter.value, parameter: filter.parameterName, value: data }
        })
      )
      onOpenIndex(`${path.part}/${indexModelKey}`, page.path)
    },
    [path.part, page, data, dispatch, onOpenIndex]
  )

  const renderLargeField = useCallback(
    (config: FieldConfig) => (
      <LargeFields
        key={config?.name}
        modelKey={modelKey}
        hasWriteAccess={hasWriteAccess}
        createMode={createMode}
        config={config}
        onOpenForm={handleOpenForm}
        onOpenIndexTable={handleOpenIndexTable}
        parentPage={page}
      />
    ),
    [modelKey, hasWriteAccess, createMode, handleOpenForm, handleOpenIndexTable, page]
  )
  const renderSmallField = useCallback(
    (config: FieldConfig) => (
      <SmallFields
        key={config?.name}
        hasWriteAccess={hasWriteAccess}
        createMode={createMode}
        config={config}
        onOpenForm={handleOpenForm}
      />
    ),
    [hasWriteAccess, createMode, handleOpenForm]
  )

  return (
    <CardWrapper
      style={{ width: card.width }}
      active={card.active}
      activeComponent={<CollapsedCard title={pageTitle} page={page} />}
    >
      <Card>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={{ ...(initialValues ?? {}), ...(fieldData ?? {}) }}
          onSubmit={handleSubmitForm}
        >
          {({ isValid, isSubmitting, handleSubmit }) => (
            <StyledForm>
              <FormCardHeader
                page={page}
                card={card}
                disableActions={createMode || isSubmitting}
                disabledSubmit={!isValid || isSubmitting}
                onSubmit={handleSubmit}
                title={title}
                autoSave={<AutoSave debounceMs={300} />}
                showActions={false}
              />
              <FormWrapper>
                <CardTitle>
                  <Display size='sm'>{pageTitle}</Display>
                </CardTitle>
                {!createMode && (!called || loading) && !fieldData ? (
                  <Spinner />
                ) : (
                  <Fields hasLargeFields={hasLargeFields}>
                    {hasLargeFields && <LargeFieldsColumn>{large.map(renderLargeField)}</LargeFieldsColumn>}
                    <SmallFieldsColumns>{small.map(renderSmallField)}</SmallFieldsColumns>
                  </Fields>
                )}
              </FormWrapper>
            </StyledForm>
          )}
        </Formik>
      </Card>
    </CardWrapper>
  )
}

export default SubformCard
