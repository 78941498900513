import React, { useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'

import { Text } from '@/components/typography/Text'
import SelectValue, { SelectValueType } from '@/components/blocks/SelectValue'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'

import { SelectContainer, SelectPopup, SelectValueContainer } from './styles'

export type SelectOption = {
  value: string
  name: string
  icon?: React.ReactNode
  color?: string
}
export type SelectProps = {
  className?: string
  value?: string
  label?: string
  error?: string
  info?: string
  disabled?: boolean
  optional?: boolean
  options?: SelectOption[]
  valueType?: SelectValueType

  onSelect?: (value: string) => void
  onClear?: () => void
  onClose?: () => void
}

const Select: React.FC<SelectProps> = ({
  className,
  label,
  value,
  error,
  info,
  disabled,
  optional,
  options,
  valueType,

  onSelect,
  onClear,
  onClose: onCloseCallback
}) => {
  const { isOpen, containerRef, controlRef, containerStyle, onToggle, onClose } = useOutsideClickHandler<
    HTMLDivElement,
    HTMLDivElement
  >({ calculatePosition: true, onClose: onCloseCallback })
  const activeOption = useMemo(
    () => (options || []).find(({ value: optionValue }) => optionValue === value),
    [value, options]
  )

  const handleSelect = useCallback(
    (newValue: string) => () => {
      onSelect && onSelect(newValue)
      onClose()
    },
    [onSelect, onClose]
  )

  return (
    <SelectContainer className={['select', className].join(' ')}>
      <SelectValueContainer ref={controlRef}>
        {label && (
          <Text size='xs' className='label'>
            {label}
          </Text>
        )}
        <SelectValue
          active={isOpen}
          valueType={valueType}
          value={activeOption}
          disabled={disabled}
          controlsOnHover={disabled}
          onClick={onToggle}
          onClear={optional ? onClear : undefined}
        />
        {error && (
          <Text size='sm' weight='regular' className='error'>
            {error}
          </Text>
        )}
        {info && (
          <Text size='sm' weight='regular' className='info'>
            {info}
          </Text>
        )}
      </SelectValueContainer>
      <>
        {!disabled &&
          isOpen &&
          ReactDOM.createPortal(
            <SelectPopup ref={containerRef} style={containerStyle}>
              {(options || []).map((option) => (
                <SelectValue
                  key={option.value}
                  type='option'
                  active={value === option.value}
                  valueType={valueType}
                  value={option}
                  onClick={handleSelect(option.value)}
                />
              ))}
            </SelectPopup>,
            document.body
          )}
      </>
    </SelectContainer>
  )
}

Select.defaultProps = {
  className: undefined,
  value: '',
  label: undefined,
  error: undefined,
  info: undefined,
  disabled: false,
  optional: true,
  options: [],
  valueType: 'badge',

  onSelect: undefined,
  onClear: undefined,
  onClose: undefined
}

export default Select
