import { schema } from '@/helpers/schema'
import { getFieldValueGetter } from './valueGetter'

export type TitleConfig = { key: string; getValue: (value: any) => string }
export const TITLE_GETTER_BY_MODEL_KEY: Record<string, TitleConfig> = Object.keys(schema.models).reduce(
    (accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if (
            (model.type === 'Type' || model.type === 'ObjectType' || model.type === 'Class') &&
            model.ui.__field_title_ref
        ) {
            const field = model.ui.__field_title_ref
            const valueGetter = getFieldValueGetter(field)
            return {
                ...accumulator,
                [modelKey]: { key: field.__key, getValue: valueGetter }
            }
        }
        return accumulator
    },
    {}
)
