import React, { useCallback, useState } from 'react'
import { IItemProps } from 'react-movable'
import Button from '@/components/controls/Button'
import { ButtonProps } from '@/components/controls/Button/types'
import ChevronDown from '@/components/icons/menu/ChevronDown'
import Close from '@/components/icons/cardHeader/Close'

import { FieldState, PreviewFields } from '@/helpers/model/form/fields'
import Maximize from '@/components/icons/cardHeader/Maximize'

import {
  Controls,
  ImagePreviewContainer,
  SelectOptionContainer,
  SelectValueContainer,
  SelectValueContent
} from './styles'

export type SelectValueType = 'image'
export type SelectSearch = { query: string; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }
export type SelectValueProps = {
  type?: 'value' | 'option'
  dndProps?: IItemProps & { isDragged?: boolean }

  active?: boolean
  disabled?: boolean
  controlsOnHover?: boolean

  value?: string
  state?: FieldState
  search?: SelectSearch
  previewFields?: PreviewFields

  onClick?: (event?: React.MouseEvent<any>) => void
  onOpen?: (expanded: boolean) => void
  onClear?: () => void
}

const CONTROL_BUTTON_PROPS = {
  size: 'xs',
  icon: 'icon',
  styleType: 'tertiary',
  variant: 'gray'
} as Partial<ButtonProps>

const ImageRowValue = React.forwardRef<HTMLDivElement, SelectValueProps>(
  (
    {
      type,
      dndProps,

      active,
      disabled,
      controlsOnHover,

      value,

      onClick,
      onOpen,
      onClear
    },
    ref
  ) => {
    const [expanded, setExpanded] = useState(false)
    const toggleExpand = useCallback(() => {
      setExpanded(!expanded)
    }, [expanded])
    const handleClear = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        onClear?.()
      },
      [onClear]
    )
    const handleClick = useCallback(
      (event: React.MouseEvent<any>) => {
        event.preventDefault()
        event.stopPropagation()
        onClick?.(event)
      },
      [onClick]
    )
    const renderContent = useCallback(() => {
      if (!value) {
        return null
      }
      const className = expanded ? 'expanded' : ''
      return <ImagePreviewContainer className={className} src={value} loading='lazy' alt='' />
    }, [value, expanded])

    if (type === 'value') {
      let hasControls = !!(onClick || onClear || onOpen)
      if (disabled && !onOpen) {
        hasControls = false
      }
      const chevronClass = ['chevrone-down', 'rotate180', active ? 'opened' : 'closed'].join(' ')
      return (
        <>
          <SelectValueContainer controlsOnHover={controlsOnHover}>
            <SelectValueContent
              data-movable-handle
              {...(dndProps || {})}
              role='button'
              ref={ref}
              dragable={!!dndProps}
              valueType='image'
              active={active}
              disabled={disabled}
              onClick={!disabled ? handleClick : undefined}
            >
              {renderContent()}
              {hasControls && (
                <Controls>
                  <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={toggleExpand}>
                    <Maximize />
                  </Button>
                  {!disabled && value && onClear && (
                    <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleClear}>
                      <Close />
                    </Button>
                  )}
                  {!disabled && onClick && (
                    <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleClick}>
                      <ChevronDown className={chevronClass} />
                    </Button>
                  )}
                </Controls>
              )}
            </SelectValueContent>
          </SelectValueContainer>
        </>
      )
    }
    return (
      <SelectOptionContainer
        {...(dndProps || {})}
        ref={ref}
        dragable={!!dndProps}
        valueType='image'
        active={active}
        onClick={onClick}
      >
        {renderContent()}
      </SelectOptionContainer>
    )
  }
)

ImageRowValue.defaultProps = {
  type: 'value',
  dndProps: undefined,

  active: false,
  disabled: false,
  controlsOnHover: false,

  value: undefined,
  state: undefined,
  search: undefined,
  previewFields: undefined,

  onClick: undefined,
  onOpen: undefined,
  onClear: undefined
}

export default ImageRowValue
