import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Formik } from 'formik'

import LoginLayout from '@/components/layouts/LoginLayout'
import InputField from '@/components/fields/InputField'

import forgotPasswordSchema from '@/helpers/validators/forms/forgotPassword'
import { EMAIL_FIELD } from '@/constants/forms/forgotPassword'
import { SIGNIN_URL } from '@/constants/routes'

import {
  FieldWrapper,
  StyledForm,
  ActionButton,
  InputBlock,
  Title,
  Info,
  ButtonsContainer,
  SuccessMessageWrapper
} from '@/components/layouts/LoginLayout/styles'

export interface ForgotPasswordFormValues {
  [EMAIL_FIELD]: string
}

const NO_EMAIL_MESSAGE = 'There’s no Folio account with the email you provided'

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [messageRecepient, setMessageRecepient] = useState()

  // TODO: change any type
  const handleSubmit = useCallback(async (values: ForgotPasswordFormValues, { setFieldError }: any) => {
    setMessageRecepient(undefined)
    try {
      const {
        CodeDeliveryDetails: { Destination }
      } = await Auth.forgotPassword(values.email)
      setMessageRecepient(Destination)
    } catch (e) {
      setFieldError(EMAIL_FIELD, NO_EMAIL_MESSAGE)
    }
  }, [])

  const handleBackClick = useCallback(() => {
    navigate(SIGNIN_URL)
  }, [navigate])

  const renderSuccessMessage = useCallback(
    () => (
      <SuccessMessageWrapper>
        <p>
          An email is on its way to
          <br />
          <b>{messageRecepient}</b> with instructions for resetting your password.
        </p>
        <br />
        <br />
        <p>
          If you do not receive the email soon, check that the email address you entered is correct, check your spam
          folder.
        </p>
        <ButtonsContainer>
          <ActionButton type='button' size='lg' fullWidth onClick={handleBackClick}>
            Return to sign in
          </ActionButton>
        </ButtonsContainer>
      </SuccessMessageWrapper>
    ),
    [messageRecepient, handleBackClick]
  )

  return (
    <LoginLayout onBack={handleBackClick}>
      <Title>Forgot your password?</Title>
      <Info margin='16px 0px' weight='regular'>
        No problem! We&apos;ll send you a link to reset it. Please enter the email address you use.
      </Info>
      {(() => {
        if (messageRecepient) {
          return renderSuccessMessage()
        }
        return (
          <Formik
            initialValues={{} as ForgotPasswordFormValues}
            validationSchema={forgotPasswordSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            <StyledForm>
              <InputBlock>
                <FieldWrapper>
                  <InputField inputSize='md' label='EMAIL ADDRESS' name={EMAIL_FIELD} />
                </FieldWrapper>
              </InputBlock>
              <ButtonsContainer>
                <ActionButton type='submit' size='lg' fullWidth>
                  Send reset link
                </ActionButton>
              </ButtonsContainer>
            </StyledForm>
          </Formik>
        )
      })()}
    </LoginLayout>
  )
}

export default ForgotPasswordPage
