import styled from 'styled-components'

import { getSvgColors } from '@/helpers/styles'
import { TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from '@/constants/styles/typography/text'
import { SelectValueType } from '.'

export const PreviewFieldsPopup = styled.div`
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 300px;
    padding: 16px;
    overflow: auto;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    ${({ theme }) => theme.shadows.regular.xl};

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
    .cell {
        width: 100%;
    }
`

export const Controls = styled.div.attrs(() => ({ className: 'controls' }))`
    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    height: 96%;
    padding: 0 7px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.base.white};
    transition: all 100ms linear;

    ${({ theme }) => getSvgColors(theme.colors.gray['400'], '.chevrone-down')};
`

export const SearchInput = styled.input.attrs(() => ({ type: 'text' }))<{ empty: boolean }>`
    position: absolute;
    inset: 0;
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 8px 14px;
    color: ${({ theme }) => theme.colors.gray['900']};
    ${TEXT_WEIGHT_STYLES.medium}
    ${TEXT_SIZE_STYLES.sm}
    transition: all 100ms linear;

    ${({ empty }) => empty && 'opacity: 0.5;'}
`

export const SelectOptionContainer = styled.div<{
    active?: boolean
    dragable?: boolean
    isDragged?: boolean
    valueType?: SelectValueType
}>`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.base.white};
    padding: 8px 12px;
    transition: all linear 100ms;

    .state-badge {
        margin-left: 4px;
    }

    &:hover {
        background: ${({ theme }) => theme.colors.gray['100']};
    }

    ${({ dragable }) => dragable && `cursor: grab;`}
    ${({ isDragged }) => isDragged && `cursor: grabbing;`}

    ${({ valueType }) =>
        valueType === 'icon-text' &&
        `
        padding: 8px;
        .text-name {
            margin-left: 4px;
        }
    `}
    ${({ valueType }) =>
        valueType === 'badge' &&
        `
        justify-content: space-between;
        padding: 3px 6px;
    `}
    ${({ theme, active }) =>
        active &&
        `
        background: ${theme.colors.gray['100']};
    `}
    ${({ onClick }) =>
        onClick &&
        `
        cursor: pointer;
    `}
`
export const SelectValueContainer = styled.div<{
    controlsOnHover?: boolean
}>`
    position: relative;

    .preview {
        display: none;
    }
    &:hover {
        .preview {
            display: flex;
        }
    }

    ${({ controlsOnHover }) =>
        controlsOnHover &&
        `
        .controls {
            display: none;
        }
        &:hover {
            .controls {
                display: flex;
            }
        }
    `}
`
export const SelectValueContent = styled.div<{
    active?: boolean
    disabled?: boolean
    dragable?: boolean
    isDragged?: boolean
    valueType?: SelectValueType
}>`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.base.white};
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    transition: all linear 100ms;

    .badge,
    .badge .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .state-badge {
        margin-left: 4px;
        flex: 0 0 auto;
    }

    ${({ dragable }) => dragable && `cursor: grab;`}
    ${({ isDragged }) => isDragged && `cursor: grabbing;`}

    ${({ valueType }) =>
        valueType === 'icon-text' &&
        `
        padding: 8px;
        .text-name {
            margin-left: 4px;
        }
    `}
    ${({ valueType }) =>
        valueType === 'badge' &&
        `
        justify-content: space-between;
        padding: 6px;
    `}
    ${({ theme, onClick }) =>
        onClick &&
        `
        cursor: pointer;
        &:hover {
            border-color: ${theme.colors.primary['300']};
        }
    `}
    ${({ theme, active }) =>
        active &&
        `
        border-color: ${theme.colors.primary['300']};
        box-shadow: 0px 0px 0px 2px ${theme.colors.primary['100']};
    `}
    ${({ theme, disabled }) =>
        disabled &&
        `
        outline: none;
        pointer-events: none;
        color: ${theme.colors.gray['400']};
        background: ${theme.colors.gray['100']};
        border-color: ${theme.colors.gray['300']};
    `}
`
