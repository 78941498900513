import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { AsyncSelectValue } from '@/components/controls/AsyncSelect'
import { requestSearchData } from '@/helpers/requests/search'
import { requestTableData } from '@/helpers/requests/table'

import { selectClient } from '@/redux/selectors/common/client'
import useDebounce from './useDebounce'

export const useAsyncOptions = (modelKey?: string, excludedOptions: string[] = []) => {
    const client = useSelector(selectClient)

    const [query, setQuery] = useState('')
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<AsyncSelectValue[]>([])

    const handleRequestOptions = useCallback(
        async (term = query) => {
            try {
                if (!modelKey) {
                    return
                }
                setLoading(true)
                if (term) {
                    const { items } = await requestSearchData(modelKey, client, { limit: 100, term })
                    const filteredItems = items.filter(({ id }) => !excludedOptions.includes(id))
                    setOptions(filteredItems)
                } else {
                    const { items } = await requestTableData(modelKey, client, { limit: 100 })
                    const filteredItems = items.filter(({ id }) => !excludedOptions.includes(id))
                    setOptions(filteredItems)
                }
            } finally {
                setLoading(false)
            }
        },
        [client, modelKey, excludedOptions, query]
    )

    const handleRequestOptionsDebounce = useDebounce(handleRequestOptions, 300)

    const handleChangeQuery = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value || ''
            setQuery(value)
            handleRequestOptionsDebounce(value)
        },
        [handleRequestOptionsDebounce]
    )

    return {
        query,
        options,
        loading,
        noData: !loading && options.length === 0,
        onChangeQuery: handleChangeQuery,
        onRequestOptions: handleRequestOptions
    }
}
