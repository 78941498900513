import React, { useCallback, useMemo, useState } from 'react'

import { CardHeader } from '@/components/regions/Card'
import Button from '@/components/controls/Button'
import { Variant } from '@/components/controls/Button/types'
import Dropdown from '@/components/controls/Dropdown'
import { Badge } from '@/components/blocks/Badge'
import { BadgeVariant } from '@/components/blocks/Badge/types'
import Tooltip from '@/components/blocks/Tooltip'
import { Text } from '@/components/typography/Text'

import Search from '@/components/icons/cardHeader/Search'
import MoreDots from '@/components/icons/cardHeader/MoreDots'
import Close from '@/components/icons/cardHeader/Close'
import Minimize from '@/components/icons/cardHeader/Minimize'
import Maximize from '@/components/icons/cardHeader/Maximize'
import ArrowLeft from '@/components/icons/search/ArrowLeft'

import theme from '@/constants/styles/theme'

import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import FormDropdownMenu from '../DropdownMenu'
import { FormCardProps } from '../..'

type Props = FormCardProps & {
  disableActions: boolean
  disabledSubmit?: boolean
  onSubmit?: () => void
  onOpenSearch?: () => void
  title?: string
  autoSave?: React.ReactNode
  showActions?: boolean
  showDelete?: boolean
  showAdditionalActions?: boolean
  dirty?: boolean
}
const FormCardHeader: React.FC<Props> = ({
  page,
  card: { expandable },
  disableActions,
  disabledSubmit,
  onSubmit,
  onOpenSearch,
  title,
  autoSave,
  showActions = true,
  showDelete,
  showAdditionalActions,
  dirty
}) => {
  const { hasWriteAccess } = useCognitoUser()
  const { onExpand, onClose } = useCardsHelper()
  const [confirmation, setConfirmation] = useState(false)

  const { model, part } = useMemo(() => getConfigByPathname(page.path), [page.path])

  const handleClose = useCallback(() => {
    if (dirty && !confirmation) {
      setConfirmation(true)
    } else {
      onClose(page.path)
    }
  }, [page.path, onClose, dirty, confirmation])

  const handleSave = useCallback(() => {
    onSubmit && onSubmit()
    handleClose()
  }, [onSubmit, handleClose])

  return (
    <CardHeader>
      <div className='part'>
        <Tooltip position='bottom' description='Search'>
          <Button
            type='button'
            icon='icon'
            size='sm'
            styleType='tertiary'
            variant='gray'
            disabled={!onOpenSearch}
            onClick={onOpenSearch}
          >
            <Search />
          </Button>
        </Tooltip>
        <Badge size='xs' variant={model.variant as BadgeVariant}>
          {model.icon}
          <Text size='xs'>{title ?? model.title}</Text>
        </Badge>
      </div>
      {!confirmation && (
        <div className='part'>
          {expandable && (
            <Tooltip position='bottom' description={page.expanded ? 'Collapse' : 'Expand'}>
              <Button
                type='button'
                icon='icon'
                size='sm'
                styleType='tertiary'
                variant='gray'
                onClick={onExpand(page.path, !page.expanded)}
              >
                {page.expanded ? <Minimize /> : <Maximize />}
              </Button>
            </Tooltip>
          )}
          {hasWriteAccess && !autoSave && (
            <Button
              type='submit'
              size='sm'
              variant={part.variant as Variant}
              disabled={disabledSubmit}
              onClick={onSubmit}
            >
              Save
            </Button>
          )}
          {hasWriteAccess && autoSave && <Text size='xs'>{autoSave}</Text>}
          {showActions && (
            <Dropdown
              control={
                <Tooltip position='bottom' description='Menu'>
                  <Button type='button' icon='icon' size='sm' styleType='tertiary' variant='gray'>
                    <MoreDots />
                  </Button>
                </Tooltip>
              }
              popup={(onCloseMenu) => (
                <FormDropdownMenu
                  disableActions={disableActions}
                  page={page}
                  onCloseMenu={onCloseMenu}
                  showDelete={showDelete ?? true}
                  showAdditionalActions={showAdditionalActions ?? true}
                />
              )}
            />
          )}
          <Tooltip position='bottom' description='Close page' arrowPosition='right'>
            <Button type='button' icon='icon' size='sm' styleType='tertiary' variant='gray' onClick={handleClose}>
              <Close />
            </Button>
          </Tooltip>
        </div>
      )}
      {confirmation && (
        <div className='part'>
          <Text size='xs' style={{ color: theme.colors.error['500'] }}>
            You have unsaved changes
          </Text>
          <Button type='submit' size='sm' variant='violet' onClick={handleSave}>
            Save & Close
          </Button>
          <Button type='reset' size='sm' variant='error' onClick={handleClose}>
            Discard
          </Button>
          <Tooltip position='bottom' description='Close page' arrowPosition='right'>
            <Button
              type='button'
              icon='icon'
              size='sm'
              styleType='tertiary'
              variant='gray'
              onClick={() => setConfirmation(false)}
            >
              <ArrowLeft transform='rotate(180)' />
            </Button>
          </Tooltip>
        </div>
      )}
    </CardHeader>
  )
}

FormCardHeader.defaultProps = {
  disabledSubmit: false,
  onSubmit: undefined,
  onOpenSearch: undefined,
  title: undefined,
  autoSave: false,
  showActions: true,
  showDelete: true,
  showAdditionalActions: true,
  dirty: false
}

export default FormCardHeader
