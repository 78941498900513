import { schema } from '@/helpers/schema'
import { getFieldValueGetter } from './valueGetter'

export type WorkflowConfig = { key: string; getValue: (value: any) => string }
export const WORKFLOW_GETTER_BY_MODEL_KEY: Record<string, WorkflowConfig> = Object.keys(schema.models).reduce(
    (accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if ((model.type === 'Type' || model.type === 'ObjectType') && model.ui.__field_workflow_ref) {
            const field = model.ui.__field_workflow_ref
            const valueGetter = getFieldValueGetter(field)
            return {
                ...accumulator,
                [modelKey]: { key: field.__key, getValue: valueGetter }
            }
        }
        return accumulator
    },
    {}
)
