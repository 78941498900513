import styled from 'styled-components'

interface ColorMarkerProps {
    backgroundColor?: string
}
export const InputColorMarker = styled.div<ColorMarkerProps>`
    position: absolute;
    right: 2px;
    height: calc(100% - 4px);
    aspect-ratio: 1 / 1;
    border-radius: 6px;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
`
