import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import ClassSelect, { ClassSelectProps, ClassValueType } from '@/components/controls/ClassSelect'

interface ClassFieldProps extends ClassSelectProps {
  name: string
}

const ClassField: React.FC<ClassFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<ClassValueType[]>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name)
      const onChange = (values: ClassValueType) => {
        form.setFieldValue(field.name, values)
      }

      return <ClassSelect {...field} {...props} error={error} disabled={isSubmitting || disabled} onChange={onChange} />
    }}
  </Field>
)

export default ClassField
