import styled from 'styled-components'

export const SelectContainer = styled.div<{ children?: JSX.Element | JSX.Element[] }>`
    position: relative;
    display: flex;
    align-items: center;
`

export const SelectValueContainer = styled.div`
    width: 100%;

    .label {
        margin: 0 14px 4px;
        color: ${({ theme }) => theme.colors.gray['700']};
    }
    .error {
        margin: 4px 14px 0;
        color: ${({ theme }) => theme.colors.error['500']};
    }
    .info {
        margin: 4px 14px 0;
        color: ${({ theme }) => theme.colors.gray['500']};
    }
`

export const SelectPopup = styled.div`
    max-height: 300px;
    min-width: 100%;
    padding: 4px 0;
    overflow: auto;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    ${({ theme }) => theme.shadows.regular.xl};

    .no-data {
        padding: 6px;
        color: ${({ theme }) => theme.colors.gray['500']};
    }
    .spinner {
        padding: 12px;
    }
`
