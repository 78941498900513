import * as React from 'react'

function Draggable(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        d={`M13 6a1 1 0 11-2 0 1 1 0 012 0zM9 6a1 1 0 11-2 0 1 1 0 012 0zM9 10a1 1 0 11-2 0 1 1 0 012 0zM13 
            10a1 1 0 11-2 0 1 1 0 012 0zM13 14a1 1 0 11-2 0 1 1 0 012 0zM9 14a1 1 0 11-2 0 1 1 0 012 0z`}
        fill='#667085'
        className='fill'
      />
    </svg>
  )
}

export default React.memo(Draggable)
