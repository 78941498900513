import * as yup from 'yup'
import { buildYup } from 'json-schema-to-yup'

import { schema } from '@/helpers/schema'
import { Field, FieldClass, FieldNumberLike, FieldObject, FieldRef, FieldStringLike, ModelType } from '@/client/schema'
import { getFormFieldName } from './fields'

const createConfigByJSONScheme = (jsonScheme: any) => {
    const { properties } = jsonScheme
    const messages = Object.keys(properties).reduce(
        (accumulator, field) => ({ ...accumulator, [field]: { required: `${field} required in JSON` } }),
        {}
    )
    return { errMessages: messages }
}

type CreateValidationParams = number | string | ((value: string) => boolean)
export type CreateValidation = { name: string; params?: CreateValidationParams[] }
export const createFieldValidation = (array: CreateValidation[]) =>
    array.reduce((result, { name, params = [] }) => result[name](...params), yup as any)

export const getStringValidation = (field: FieldStringLike) => {
    const name = getFormFieldName(field)
    const validators: CreateValidation[] = [{ name: 'string' }]
    if (!field.optional) {
        validators.push({ name: 'required', params: ['Required'] })
    } else {
        validators.push({ name: 'nullable' })
    }
    if (typeof field.minLength === 'number') {
        validators.push({ name: 'min', params: [field.minLength, `Minimum ${field.minLength} characters`] })
    }
    if (typeof field.maxLength === 'number') {
        validators.push({ name: 'max', params: [field.maxLength, `Maximum ${field.maxLength} characters`] })
    }
    if (field.pattern) {
        const regexp = new RegExp(field.pattern)
        validators.push({
            name: 'test',
            params: [`${name}-test`, field.description!, (value: string) => regexp.test(value)]
        })
    }
    return createFieldValidation(validators)
}
export const getNumberValidation = (field: FieldNumberLike) => {
    const validators: CreateValidation[] = [{ name: 'number' }]
    if (!field.optional) {
        validators.push({ name: 'required', params: [`Required. ${field.description || ''}`] })
    } else {
        validators.push({ name: 'nullable' })
    }
    if (field.type === 'Int') {
        validators.push({ name: 'integer', params: ['Integer value'] })
    }
    if (typeof field.min === 'number') {
        validators.push({ name: 'min', params: [field.min, `Minimum ${field.min}`] })
    }
    if (typeof field.minExclusive === 'number') {
        validators.push({ name: 'min', params: [field.minExclusive, `Minimum ${field.minExclusive}`] })
    }
    if (typeof field.max === 'number') {
        validators.push({ name: 'max', params: [field.max, `Maximum ${field.max}`] })
    }
    if (typeof field.maxExclusive === 'number') {
        validators.push({ name: 'max', params: [field.maxExclusive, `Maximum ${field.maxExclusive}`] })
    }

    return createFieldValidation(validators)
}
export const getColorValidation = (field: FieldStringLike) => {
    const name = getFormFieldName(field)
    const validators: CreateValidation[] = [{ name: 'string' }]
    if (!field.optional) {
        validators.push({ name: 'required', params: ['Required'] })
    } else {
        validators.push({ name: 'nullable' })
    }
    if (typeof field.minLength === 'number') {
        validators.push({ name: 'min', params: [field.minLength, `Minimum ${field.minLength} characters`] })
    }
    if (typeof field.maxLength === 'number') {
        validators.push({ name: 'max', params: [field.maxLength, `Maximum ${field.maxLength} characters`] })
    }
    const hexPattern = /^#(?:[0-9a-fA-F]{3,4}){1,2}$/
    validators.push({
        name: 'test',
        params: [`${name}-test`, `Wrong hex color format`, (value: string) => hexPattern.test(value)]
    })
    return createFieldValidation(validators)
}
export const getEmailValidation = (field: FieldStringLike) => {
    const validators: CreateValidation[] = [{ name: 'string' }, { name: 'email', params: [field.description!] }]
    if (!field.optional) {
        validators.push({ name: 'required', params: [`Required. ${field.description || ''}`] })
    } else {
        validators.push({ name: 'nullable' })
    }

    return createFieldValidation(validators)
}
export const getURLValidation = (field: FieldStringLike) => {
    const validators: CreateValidation[] = [{ name: 'string' }, { name: 'url', params: [field.description!] }]
    if (!field.optional) {
        validators.push({ name: 'required', params: [`Required. ${field.description || ''}`] })
    } else {
        validators.push({ name: 'nullable' })
    }

    return createFieldValidation(validators)
}
export const getEnumValidation = (field: FieldRef) => {
    const validators: CreateValidation[] = [{ name: 'string' }]
    if (!field.optional) {
        validators.push({ name: 'required', params: [`Required. Select value`] })
    } else {
        validators.push({ name: 'nullable' })
    }

    return createFieldValidation(validators)
}
export const getJSONValidation = (field: FieldStringLike) => {
    const config = createConfigByJSONScheme(field.schema)
    const yupValidation = buildYup(field.schema, { ...config, log: true })

    if (field.optional) {
        return yupValidation.nullable()
    }

    return yupValidation
}
export const getObjectValidation = (field: FieldObject) => {
    const validators: CreateValidation[] = [{ name: 'string' }]
    if (!field.optional) {
        validators.push({ name: 'required', params: ['Required'] })
    } else {
        validators.push({ name: 'nullable' })
    }
    return createFieldValidation(validators)
}

export const getClassValidation = (field: FieldClass) => {
    const validators: CreateValidation[] = [{ name: 'string' }]
    if (!field.optional) {
        validators.push({ name: 'required', params: ['Required'] })
    } else {
        validators.push({ name: 'nullable' })
    }
    return createFieldValidation(validators)
}

type GetFieldValidation = (field: Field) => any
const getFieldValidation: GetFieldValidation = (field: Field) => {
    if (field.type === 'String') {
        return getStringValidation(field)
    }
    if (field.type === 'Color') {
        return getColorValidation(field)
    }
    if (field.type === 'Float' || field.type === 'Int') {
        return getNumberValidation(field)
    }
    if (field.type === 'Email') {
        return getEmailValidation(field)
    }
    if (field.type === 'URL') {
        return getURLValidation(field)
    }
    if (field.type === 'JSON' && field.schema) {
        return getJSONValidation(field)
    }
    if (field.type === 'Object') {
        return getObjectValidation(field)
    }
    if (field.type === 'Class') {
        return getClassValidation(field)
    }
    if (field.type === 'Ref' && field.__kind === 'RefEnum') {
        return getEnumValidation(field)
    }
    if (
        field.type === 'ID' ||
        field.type === 'Phone' ||
        field.type === 'IPAddress' ||
        (field.type === 'List' && field.__kind === 'ArrayLikeOfScalar')
    ) {
        const validators: CreateValidation[] = [{ name: 'string' }]
        if (!field.optional) {
            validators.push({ name: 'required', params: [`Required. ${field.description || ''}`] })
        } else {
            validators.push({ name: 'nullable' })
        }

        return createFieldValidation(validators)
    }
    if (field.type === 'Set' || field.type === 'List') {
        const validators: CreateValidation[] = [{ name: 'array' }]
        if (!field.optional) {
            validators.push({ name: 'required', params: [`Required. ${field.description || ''}`] })
        } else {
            validators.push({ name: 'nullable' })
        }
        return createFieldValidation(validators)
    }
    if (field.type === 'Ref' && field.__kind === 'RefType') {
        if (!field.optional) {
            const validators: CreateValidation[] = [{ name: 'string' }]
            validators.push({ name: 'required', params: [`Required. ${field.description || ''}`] })
            return yup
                .object()
                .shape({ id: createFieldValidation(validators) })
                .nullable()
        }
    }

    return undefined
}

const getValidationByModelKey = (modelKey: string) => {
    const model = schema.models[modelKey] as ModelType
    const fieldsValidation = Object.keys(model.fields).reduce((accumulator, selector) => {
        const field = model.fields[selector]
        if (selector === 'id' || field.external) {
            return accumulator
        }
        return { ...accumulator, [selector]: getFieldValidation(field) }
    }, {})

    return yup.object().shape(fieldsValidation)
}

export const FORM_VALIDATION_SCHEMA_BY_MODEL_KEY: Record<string, any> = Object.keys(schema.models).reduce(
    (accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if (model.type === 'Type' || model.type === 'ObjectType' || model.type === 'Class') {
            return { ...accumulator, [modelKey]: getValidationByModelKey(modelKey) }
        }
        return accumulator
    },
    {}
)
