import styled from 'styled-components'

export const Container = styled.div`
    padding: 24px 0;
    min-width: 600px;
    max-width: 1000px;
`

export const Fields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`
export const FieldsContainer = styled(Fields)`
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 24px;
    margin: 24px 0;
`

export const Buttons = styled.div<{ padding?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    ${({ padding }) => padding && 'padding: 0 24px;'}
`
