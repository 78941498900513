import React, { useCallback, useMemo } from 'react'

import SelectValue from '@/components/blocks/SelectValue'
import LargeFieldBlock, { LargeFieldBlockProps } from '@/components/blocks/LargeFieldBlock'
import { FieldState, PreviewFields } from '@/helpers/model/form/fields'
import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'
import Button from '../Button'

import { ControlsRow, Values } from './styles'

export type IndexViewValue = { items: any[]; nextToken: string | null }
export type IndexViewProps = LargeFieldBlockProps & {
  value?: IndexViewValue
  modelKey?: string
  state?: FieldState
  previewFields?: PreviewFields

  onOpen?: (id: string, expanded: boolean) => void
  onOpenIndexTable?: () => void
}
export const DEFAULT_VISIBLE_COUNT = 10

const IndexView: React.FC<IndexViewProps> = ({
  value,
  modelKey,
  state,
  previewFields,

  onOpen,
  onOpenIndexTable,

  ...fieldBlockProps
}) => {
  const titleConfig = useMemo(() => TITLE_GETTER_BY_MODEL_KEY[modelKey!], [modelKey])

  const handleOpen = useCallback((id: string) => (expanded: boolean) => onOpen && onOpen(id, expanded), [onOpen])

  return (
    <LargeFieldBlock {...fieldBlockProps}>
      {value && (
        <Values>
          {(value?.items || []).map((option) => (
            <SelectValue
              key={option.id}
              controlsOnHover
              titleConfig={titleConfig}
              value={option}
              state={state}
              previewFields={previewFields}
              onOpen={handleOpen(option.id)}
            />
          ))}
        </Values>
      )}
      <ControlsRow>
        <Button type='button' size='sm' icon='left' styleType='tertiary' variant='gray' onClick={onOpenIndexTable}>
          Show all
        </Button>
      </ControlsRow>
    </LargeFieldBlock>
  )
}

IndexView.defaultProps = {
  value: undefined,
  modelKey: undefined,
  state: undefined,
  previewFields: undefined,

  onOpen: undefined,
  onOpenIndexTable: undefined
}

export default IndexView
