import styled from 'styled-components'

import { loadingAnimation } from '@/constants/styles/animations'

export const ValueContainer = styled.div``

export const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;
`

export const ImageSC = styled.img`
    height: 80px;
    min-width: 80px;
    max-width: 200px;
    object-fit: contain;
    background: ${({ theme }) => `linear-gradient(90deg, ${theme.colors.gray['200']}, ${theme.colors.gray['300']})`};
    background-size: 200% 200%;
    animation: ${loadingAnimation} 1s ease infinite;
`

export const ImageError = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.gray['200']};
    ${({ theme }) => theme.shadows.regular.sm};
    color: ${({ theme }) => theme.colors.error['500']};
    text-align: center;
`

export const Iframe = styled.iframe`
    width: 94vw;
    height: 94vh;
    border-radius: 8px;
`

export const ImageCardContainer = styled.div`
    position: relative;
    display: flex;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    ${({ theme }) => theme.shadows.regular.xs};

    img {
        border-radius: 8px;
    }

    .delete-button {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 8px;
        right: 8px;
    }

    &:hover {
        .delete-button {
            opacity: 1;
            pointer-events: all;
        }
    }
`

export const ImageRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
`
