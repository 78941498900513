import { createReducer } from '@reduxjs/toolkit'
import {
    deleteSubformAction,
    deleteSubformUpdatedDataAction,
    setSubformAction,
    updateSubformAction
} from '@/redux/actions/subform'

export type SubformState = {
    data?: Record<string, any>
    updatedData?: Record<string, any>
    index?: number | string
    modelKey?: string
    updated: boolean
}

export type SubformReducerState = Record<string, SubformState>

const initialState: SubformReducerState = {}

export const subformReducer = createReducer(initialState, (builder) => {
    builder.addCase(setSubformAction, (state, { payload: { key, values, index, modelKey } }) => {
        state[key] = {
            data: values,
            updated: false,
            index,
            modelKey
        }
    })
    builder.addCase(updateSubformAction, (state, { payload: { key, values, index, modelKey } }) => {
        state[key] = {
            ...state[key],
            updatedData: values,
            updated: true,
            index,
            modelKey
        }
    })
    builder.addCase(deleteSubformAction, (state, { payload }) => {
        delete state[payload]
    })
    builder.addCase(deleteSubformUpdatedDataAction, (state, { payload }) => {
        state[payload] = {
            ...state[payload],
            updatedData: undefined,
            updated: false
        }
    })
})
