import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import Color from '@/components/controls/Color'

import { InputProps } from '../../controls/Input'

interface InputFieldProps extends InputProps {
  validateWithTouched?: boolean
}

const ColorField: React.FC<InputFieldProps> = ({ validateWithTouched, ...props }) => (
  <Field {...props}>
    {({ field, form, meta: { touched } }: FieldProps<string>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name)
      const fieldError = validateWithTouched ? touched && error : error

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(field.name, event.target.value)
        form.setFieldTouched(field.name)
        props.onChange && props.onChange(event)
      }

      return (
        <Color
          {...field}
          {...props}
          value={field.value || ''}
          error={fieldError}
          disabled={isSubmitting || props.disabled}
          onChange={handleChange}
        />
      )
    }}
  </Field>
)

ColorField.defaultProps = {
  validateWithTouched: false
}

export default ColorField
