import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { StyleSheetManager, ThemeProvider } from 'styled-components'
import { Provider as AlertProvider } from '@blaumaus/react-alert'

import Alert, { ALERT_OPTIONS } from '@/components/blocks/Alert'
import { ApolloProviderWithClient } from '@/hocs/ApolloProviderWithClient'
import theme from '@/constants/styles/theme'

import { store, persistor } from '@/redux/store'

import isPropValid from '@emotion/is-prop-valid'

import App from './App'

function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

const RootComponent = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
              <AlertProvider template={Alert} {...ALERT_OPTIONS}>
                <ApolloProviderWithClient>
                  <App />
                </ApolloProviderWithClient>
              </AlertProvider>
            </StyleSheetManager>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

const container = document.getElementById('root') as HTMLElement

if (process.env.NODE_ENV === 'development') {
  ReactDOM.createRoot(container).render(<RootComponent />)
} else {
  ReactDOM.hydrateRoot(container, <RootComponent />)
}
