import React from 'react'
import {
  SIGNIN_URL,
  FORGOT_PASSWORD_URL,
  SET_NEW_PASSWORD_URL,
  SIGNUP_CONFIRM_URL,
  EMAIL_CHANGE_CONFIRM_URL
} from '@/constants/routes'

import SignInPage from './SignInPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import SetNewPasswordPage from './SetNewPasswordPage'
import SignUpConfirmPage from './SignUpConfirmPage'
import ChangeEmailConfirmPage from './ChangeEmailConfirmPage'

export const AUTH_ROUTES = [
  { path: SIGNIN_URL, element: <SignInPage /> },
  { path: FORGOT_PASSWORD_URL, element: <ForgotPasswordPage /> },
  { path: SET_NEW_PASSWORD_URL, element: <SetNewPasswordPage /> },
  { path: SIGNUP_CONFIRM_URL, element: <SignUpConfirmPage /> },
  { path: EMAIL_CHANGE_CONFIRM_URL, element: <ChangeEmailConfirmPage /> }
]
