import { createAction } from '@reduxjs/toolkit'

export const SUBFORM_ACTION = 'SUBFORM'
export const SET_SUBFORM_ACTION = `${SUBFORM_ACTION}/SET`
export const UPDATE_SUBFORM_ACTION = `${SUBFORM_ACTION}/UPDATE`
export const DELETE_SUBFORM_ACTION = `${SUBFORM_ACTION}/DELETE`
export const DELETE_SUBFORM_UPDATED_DATA_ACTION = `${SUBFORM_ACTION}/DELETE_UPDATED_DATA`

export type SubformVariables = {
    key: string
    values?: Record<string, any>
    index?: number | string
    modelKey?: string
}

export const setSubformAction = createAction<SubformVariables>(SET_SUBFORM_ACTION)
export const updateSubformAction = createAction<SubformVariables>(UPDATE_SUBFORM_ACTION)
export const deleteSubformAction = createAction<string>(DELETE_SUBFORM_ACTION)
export const deleteSubformUpdatedDataAction = createAction<string>(DELETE_SUBFORM_UPDATED_DATA_ACTION)
