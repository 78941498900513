import styled from 'styled-components'
import { FieldsCompact } from '@/pages/cards/FormCard/styles'

export const Values = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 8px;
`

export const ControlsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ControlsMultilineRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 8px;
`

export const Divider = styled.div`
    height: 3px;
    width: calc(100% + 24px);
    background-color: ${({ theme }) => theme.colors.gray['100']};
    margin: 0 -12px;
`

export const ClassFieldsCompact = styled(FieldsCompact)`
    background-color: ${({ theme }) => theme.colors.base.white};
`

export const ClassWrapper = styled.div`
    .field-block {
        padding: 8px 2px 2px 2px;
    }
`
