import * as yup from 'yup'
import objectPath from 'object-path'

import { FieldNumberLike, FieldStringLike } from '@/client/schema'
import { FieldConfig } from '@/helpers/model/form/fields'
import {
    createFieldValidation,
    CreateValidation,
    getNumberValidation,
    getStringValidation,
    getJSONValidation
} from '@/helpers/model/form/validation'

import { ModalField, ModalImageField } from './types'

export const mapModalField = (field: ModalField): FieldConfig => {
    const { id: name, title: label, readonly } = field
    const params = { name, label, hideLabel: !label, disabled: { edit: readonly || false, all: false } }
    if (field.type === 'Enum') {
        const options = field.members.map((item: string) => ({ value: item, name: item }))
        return { ...params, type: 'select', options, optional: false }
    }
    if (field.type === 'String' || field.type === 'Int') {
        return { ...params, type: 'input' }
    }
    if (field.type === 'Datetime') {
        return { ...params, type: 'timestamp' }
    }
    if (field.type === 'Boolean') {
        return { ...params, type: 'checkbox' }
    }
    if (field.type === 'JSON') {
        return { ...params, type: 'json', large: true }
    }
    if (field.type === 'PopupButton') {
        return { ...params, type: 'custom' }
    }
    if (field.type === 'Image') {
        const { modalPath, path, extension, pathWithoutSize } = field as ModalImageField
        return { ...params, type: 'image', modalPath, path, extension, pathWithoutSize }
    }
    return undefined
}

const getModalFieldInitialValue = (field: ModalField) => {
    if (field.type === 'String' && field.localized && field.default) {
        return JSON.parse(field.default)
    }
    if (field.type === 'Enum') {
        return field.default || field.members[0]
    }
    return field.default
}
const getModalFieldsInitialValues = (fields: ModalField[]) =>
    fields.reduce((acc, field) => {
        const intialValue = getModalFieldInitialValue(field)
        if (intialValue) {
            objectPath.set(acc, field.id, intialValue)
        }
        return acc
    }, {})

export const getModalInitialValues = (modal: any) => {
    const locale = modal?.config?.locale ? { locale: modal.config.locale.currentLanguages.join(',') } : {}
    const fields = modal.fields.reduce((accumulator: any, fieldsRow: ModalField[]) => {
        const row = getModalFieldsInitialValues(fieldsRow)
        return { ...accumulator, ...row }
    }, {})

    const sectionsFields = (modal.sections || []).reduce((accumulator: any, section: any) => {
        const values = (section.values || []).map((valuesRow: Record<string, any>) =>
            Object.keys(valuesRow).reduce((acc, key) => {
                const value = valuesRow[key]
                const field = section.fields.flat().find((f: ModalField) => f.id === key)
                if (field?.localized && value) {
                    return { ...acc, [key]: JSON.parse(value) }
                }
                return { ...acc, [key]: value }
            }, {})
        )
        return { ...accumulator, [section.id]: values }
    }, {})

    return { ...locale, ...fields, ...sectionsFields }
}

const getModalEnumValidation = (field: ModalField) => {
    const validators: CreateValidation[] = [{ name: 'string' }]
    if (!field.optional) {
        validators.push({ name: 'required', params: [`Required. Select value`] })
    } else {
        validators.push({ name: 'nullable' })
    }

    return createFieldValidation(validators)
}

const getModalFieldValidation = (field: ModalField) => {
    if (field.type === 'Enum') {
        return getModalEnumValidation(field)
    }
    if (field.type === 'String' && !field.localized) {
        return getStringValidation({ ...field, __key: field.id } as FieldStringLike)
    }
    if (field.type === 'Int') {
        return getNumberValidation({ ...field, __key: field.id } as FieldNumberLike)
    }
    if (field.type === 'JSON') {
        return getJSONValidation({ ...field, __key: field.id } as FieldStringLike)
    }
    return undefined
}

export const getModalFormValidation = (modal: any) => {
    const fieldsValidation = modal.data?.fields
        ?.flat()
        .reduce((accumulator: Record<string, any>, field: ModalField) => {
            const fieldValidation = getModalFieldValidation(field)
            if (fieldValidation) {
                return { ...accumulator, [field.id]: fieldValidation }
            }
            return accumulator
        }, {})
    const sectionsValidation = modal.data?.sections?.reduce((accumulator: Record<string, any>, section: any) => {
        const sectionValidation = section.fields.flat().reduce((acc: Record<string, any>, field: ModalField) => {
            const fieldValidation = getModalFieldValidation(field)
            if (fieldValidation) {
                return { ...acc, [field.id]: fieldValidation }
            }
            return acc
        }, {})
        return {
            ...accumulator,
            [section.id]: yup.array().of(yup.object().shape(sectionValidation))
        }
    }, {})

    return yup.object().shape({ ...fieldsValidation, ...sectionsValidation })
}

const mapModalFieldPayload = (field: ModalField, value: any, languages: string[]) => {
    if (field.localized) {
        const localizedValue = Object.keys(value || {}).reduce((acc: Record<string, any>, key) => {
            if (languages.includes(key)) {
                return { ...acc, [key]: value[key] }
            }
            return acc
        }, {})

        return JSON.stringify(localizedValue)
    }

    if (value instanceof Date) {
        value = new Date(
            Date.UTC(
                value.getFullYear(),
                value.getMonth(),
                value.getDate(),
                value.getHours(),
                value.getMinutes(),
                value.getSeconds(),
                value.getMilliseconds()
            )
        ).toISOString()
    }

    return value
}

export const mapModalFieldsPayload = (modal: any, values: Record<string, any>, languages: string[]) => {
    const payload = { ...values }
    modal.data?.fields?.flat().forEach((field: ModalField) => {
        payload[field.id] = mapModalFieldPayload(field, values[field.id], languages)
    })
    modal.data?.sections?.forEach((section: any) => {
        const fields = section.fields.flat()
        const sectionValues = payload[section.id].map((value: Record<string, any>, index: number) => {
            const sectionValue = fields.reduce((acc: Record<string, any>, field: ModalField) => {
                if (field.type === 'PopupButton') {
                    const relationValue = objectPath.get(values, `${section.id}.${index}.${field.relation}`)
                    const configFields = modal?.data.config.relation[field.relation]

                    const valueField = configFields?.[relationValue] || []
                    const defaultFields = configFields?.['*'] || []
                    const relationFields = [...valueField, ...defaultFields]

                    const relationValues = relationFields.reduce(
                        (relationAcc: Record<string, any>, relationField: ModalField) => {
                            if (typeof value[relationField.id] !== 'undefined') {
                                relationAcc[relationField.id] = mapModalFieldPayload(
                                    relationField,
                                    value[relationField.id],
                                    languages
                                )
                            }
                            return relationAcc
                        },
                        {}
                    )

                    return { ...acc, ...relationValues }
                }
                return {
                    ...acc,
                    [field.id]: mapModalFieldPayload(field, value[field.id], languages)
                }
            }, {})
            return sectionValue
        })
        payload[section.id] = sectionValues
    })

    return payload
}
