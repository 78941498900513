import React from 'react'
import { createPortal } from 'react-dom'

import useOutsideClickHandler, { OutsideClickHandlerOptions } from '@/hooks/useOutsideClickHandler'

import { DropdownControl, DropdownPopup } from './styles'

type Props = {
  control: React.ReactNode | ((open: boolean) => React.ReactNode)
  popup: React.ReactNode | ((onClose: () => void) => React.ReactNode)
  fullWidth?: boolean
  options?: OutsideClickHandlerOptions
}
const Dropdown: React.FC<Props> = ({ control, popup, fullWidth, options }) => {
  const { isOpen, containerRef, controlRef, containerStyle, onToggle, onClose } = useOutsideClickHandler<
    HTMLDivElement,
    HTMLDivElement
  >({ ...options, calculatePosition: true })

  return (
    <>
      <DropdownControl ref={controlRef} onClick={onToggle}>
        {typeof control === 'function' ? control(isOpen) : control}
      </DropdownControl>
      {isOpen &&
        createPortal(
          <DropdownPopup ref={containerRef} style={containerStyle} fullWidth={fullWidth}>
            {typeof popup === 'function' ? popup(onClose) : popup}
          </DropdownPopup>,
          document.body
        )}
    </>
  )
}

Dropdown.defaultProps = {
  options: undefined,
  fullWidth: false
}

export default Dropdown
