import { createAsyncThunk } from '@reduxjs/toolkit'

import { requestRunAction } from '@/helpers/requests/form'
import { ModalActionType, ModalType } from '@/modals/types'

import { ThunkAPI } from '../store'
import { setSelectedRowTableAction } from './table'
import { setModalAction } from './common/ui'
import { selectClient } from '../selectors/common/client'

const ACTION_ACTION = 'ACTION'
const RUN_ACTION_ACTION = `${ACTION_ACTION}/RUN`

export type RunActionRowsVariables = {
    modelKey: string
    action: string
    ids: string[]
    payload?: Record<string, string>
}
export const requestRunActionAction = createAsyncThunk<null, RunActionRowsVariables, ThunkAPI>(
    RUN_ACTION_ACTION,
    async ({ modelKey, action, ids, payload }, { dispatch, getState }) => {
        const state = getState()
        const client = selectClient(state)

        const data = payload ? { payload: JSON.stringify(payload) } : {}
        const response = await requestRunAction(modelKey, client, { ids, action, ...data })
        if (response) {
            const value = JSON.parse(response) as { action: ModalActionType } & Record<string, any>
            if (value.action === 'SHOW_DOCUMENT_QR_CODES') {
                dispatch(setModalAction({ type: ModalType.DocumentQRCodes, data: value }))
            } else if (value.action === 'INFO') {
                dispatch(setModalAction({ type: ModalType.Info, data: value }))
            } else {
                dispatch(setModalAction({ type: ModalType.Action, data: value }))
            }
        }
        if (ids.length > 1) {
            dispatch(setSelectedRowTableAction({ modelKey, ids: [] }))
        }

        return null
    }
)
