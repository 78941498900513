export const HOME_URL = '/'

export const SIGNIN_URL = '/login'
export const SIGNOUT_URL = '/sign-out'
export const SIGNUP_CONFIRM_URL = '/sign-up-confirm'
export const EMAIL_CHANGE_CONFIRM_URL = '/email-change-confirm'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const SET_NEW_PASSWORD_URL = '/set-new-password'

export const NOTIFICATIONS_URL = '/notifications'
