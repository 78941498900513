import React, { useCallback, useMemo, useState } from 'react'

import LargeFieldBlock, { LargeFieldBlockProps } from '@/components/blocks/LargeFieldBlock'
import Plus from '@/components/icons/cardHeader/Plus'
import Modal from '@/components/regions/Modal'
import { downloadImage, getImageName, getImageUrl } from '@/helpers/image'
import { wait } from '@/helpers/wait'
import config from '@/helpers/config'

import { arrayMove, List } from 'react-movable'
import { Values } from '@/components/controls/RelationSelect/styles'
import ImageRowValue from '@/components/blocks/ImageRowValue'
import { FieldState } from '@/helpers/model/form/fields'
import { Iframe, ImageRowContainer, ImagesContainer, ValueContainer } from './styles'
import ImageCard from './components/ImageCard'
import Button from '../Button'

export type ImageSelectValue = string[] | string | undefined
export type ImageSelectProps = LargeFieldBlockProps & {
  modalPath: string
  path: string
  extension: string
  size?: { width: number; height: number }
  pathWithoutSize?: boolean

  state?: FieldState
  value?: ImageSelectValue

  disabled?: boolean
  list?: boolean

  onSelect?: (value: ImageSelectValue) => void
}
const ImageSelect: React.FC<ImageSelectProps> = ({
  modalPath,
  path,
  size,
  pathWithoutSize,
  extension,

  value,

  disabled,
  list,
  state,

  onSelect,

  ...fieldBlockProps
}) => {
  const [selectModalOpen, setSelectModalOpen] = useState(false)
  const modalUrl = useMemo(() => `${config.REACT_APP_MEDIA_UPLOAD_URL}/${modalPath}`, [modalPath])

  const handlePostMessage = useCallback(
    async (event: MessageEvent) => {
      if (config.REACT_APP_MEDIA_UPLOAD_URL.includes(event.origin)) {
        if (!onSelect) return

        if (event.data !== 'close') {
          // timeout for loading image without error after adding
          await wait(2000)
          if (list) {
            onSelect([...((value as string[]) || []), event.data])
          } else {
            onSelect(event.data)
          }
        }
        setSelectModalOpen(false)
        window.removeEventListener('message', handlePostMessage, false)
      }
    },
    [list, value, onSelect]
  )
  const handleDownload = useCallback(
    (id: string) => () => {
      const url = getImageUrl(id, path, extension, size, pathWithoutSize)
      const name = getImageName(id, extension, size, pathWithoutSize)
      if (url && name) {
        downloadImage(url, name)
      }
    },
    [extension, path, size, pathWithoutSize]
  )
  const handleDelete = useCallback(
    (deleteValue: string) => () => {
      if (!onSelect) return
      if (list) {
        onSelect(((value as string[]) || []).filter((item) => item !== deleteValue))
      } else {
        onSelect(undefined)
      }
    },
    [list, value, onSelect]
  )

  const handleOpenSelectModal = useCallback(() => {
    setSelectModalOpen(true)
    window.addEventListener('message', handlePostMessage, false)
  }, [handlePostMessage])

  const renderValue = useCallback(
    (fieldValue?: string | string[]): React.ReactNode | null => {
      if (!fieldValue) {
        return null
      }
      if (Array.isArray(fieldValue)) {
        return fieldValue.map(renderValue)
      }

      return (
        <ImageRowContainer>
          <ImageCard
            key={fieldValue}
            alt=''
            disabled={disabled}
            src={getImageUrl(fieldValue, path, extension, size, pathWithoutSize)}
            onDownload={handleDownload(fieldValue)}
            onDelete={handleDelete(fieldValue)}
          />
        </ImageRowContainer>
      )
    },
    [path, extension, size, disabled, pathWithoutSize, handleDownload, handleDelete]
  )
  return (
    <>
      <LargeFieldBlock {...fieldBlockProps}>
        <ValueContainer>
          {Array.isArray(value) ? (
            <List<ImageSelectValue>
              lockVertically
              values={value as string[]}
              onChange={({ oldIndex, newIndex }) =>
                onSelect && onSelect(arrayMove(value as string[], oldIndex, newIndex))
              }
              renderList={({ children, props }) => <Values {...props}>{children}</Values>}
              renderItem={({ value: option, isDragged, props: { ref, ...props } }) => (
                <ImageRowValue
                  key={option as string}
                  ref={ref}
                  dndProps={{ ...props, isDragged }}
                  controlsOnHover
                  value={getImageUrl(option as string, path, extension, size, pathWithoutSize)}
                  state={state}
                  disabled={disabled}
                  onClear={handleDelete(option as string)}
                />
              )}
            />
          ) : (
            <ImagesContainer>{renderValue(value)}</ImagesContainer>
          )}
          {modalPath && (
            <Button
              type='button'
              size='sm'
              icon='left'
              styleType='tertiary'
              variant='gray'
              disabled={disabled || (!list && !!value)}
              onClick={handleOpenSelectModal}
            >
              <Plus />
              Add image
            </Button>
          )}
        </ValueContainer>
      </LargeFieldBlock>
      {modalPath && selectModalOpen && (
        <Modal>
          <Iframe title='image-modal' src={modalUrl} frameBorder='0' />
        </Modal>
      )}
    </>
  )
}

ImageSelect.defaultProps = {
  value: undefined,
  disabled: false,
  list: false,
  size: undefined,
  pathWithoutSize: true,
  state: undefined,

  onSelect: undefined
}

export default ImageSelect
