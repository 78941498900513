import { Field } from '@/client/schema'
import { FieldConfig } from '@/helpers/model/form/fields'
import {
    _getInputConfig,
    _getIndexConfig,
    _getListConfig,
    _getRefConfig,
    _getSetConfig
} from '@/helpers/model/form/_getFieldConfigHelpers'
import { _getFormFieldName } from '@/helpers/model/form/_getFormFieldName'

export type GetFieldConfigOptions = { allowExternal: boolean; allowFormula: boolean }

export const _getFieldConfig = (
    field: Field,
    { allowExternal = false, allowFormula = false } = {} as GetFieldConfigOptions
): FieldConfig => {
    if (field.visible === false) {
        return undefined
    }
    const isExternal = !allowExternal && field.external
    const isFormula = !allowFormula && field.type === 'Formula'
    if (isFormula) {
        return undefined
    }
    const name = _getFormFieldName(field)
    const disabled = { edit: field.readonly, all: isExternal, const: field.const }

    switch (field.type) {
        case 'String':
        case 'Int':
        case 'Float':
        case 'ID':
        case 'Phone':
        case 'Email':
        case 'URL':
            return _getInputConfig(field, name, disabled)
        case 'Color':
            return { name, disabled, type: 'color' }
        case 'Timestamp':
            return { name, disabled, type: 'timestamp' }
        case 'JSON':
            return { name, disabled, type: 'json', large: true }
        case 'Object':
            return { name, disabled, type: 'object', large: true, refs: field.__refs, field, list: false }
        case 'Class':
            return { name, disabled, type: 'class', large: true, ref: field.__ref }
        case 'Img':
            return {
                name,
                disabled,
                modalPath: field.modalPath,
                path: field.path,
                size: field.size,
                extension: field.extension,
                pathWithoutSize: field.pathWithoutSize,
                previewSize: field.previewSize,
                type: 'image',
                large: true
            }
        case 'Bundle':
            return {
                name,
                disabled,
                type: 'bundle',
                large: true,
                path: field.path,
                extension: field.extension,
                viewerLink: field.viewerLink
            }
        case 'Markdown':
            return {
                name,
                disabled,
                type: 'rich-text',
                large: true,
                imagesModalPath: field.imagesModalPath,
                imagesPath: field.imagesPath,
                imagesExtension: field.imagesExtension
            }
        case 'Boolean':
            return { name, disabled, type: 'checkbox' }
        case 'List':
            return _getListConfig(field, name, disabled, _getFieldConfig)
        case 'Index':
            return _getIndexConfig(field, name, disabled)
        case 'Ref':
            return _getRefConfig(field, name, disabled)
        case 'Set':
            return _getSetConfig(field, name, disabled)
        default:
            return undefined
    }
}
