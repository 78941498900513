/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable eqeqeq */
/* eslint-disable no-case-declarations */
/* eslint-disable guard-for-in */
/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */

/* eslint-disable no-use-before-define */

export interface Schema {
    $schema: string
    name: string
    version: string
    models: Record<string, Model>
    actions?: Record<string, Array<ActionTarget>>
}

export interface ModelBase {
    __key: string
    __schema?: Schema
    type: 'Type' | 'Enum' | 'ObjectType' | 'Class' | 'Inheritor'
}

export interface ModelType extends ModelBase {
    type: 'Type' | 'ObjectType' | 'Class' | 'Inheritor'
    fields: Record<string, Field>
    inheritors?: {
        key: RefField
        $refs: { type: string; $ref: string }[]
        __key?: Field
        __refs?: { type: string; $ref: Model }[]
    }
    indices?: Record<string, Index>
    ui: ModelTypeUI
}

export type Types =
    | TypesNumberLike
    | TypesStringLike
    | TypesArrayLike
    | 'Boolean'
    | 'Bundle'
    | 'Ref'
    | 'Markdown'
    | 'Index'
    | 'Formula'
    | 'Object'
    | 'Class'
    | 'ClassRef'

export type TypeKind =
    | 'Scalar'
    | 'ArrayLikeOfScalar'
    | 'RefType'
    | 'RefEnum'
    | 'ArrayLikeOfRefType'
    | 'ArrayLikeOfRefEnum'
    | 'Index'
    | 'Object'
    | 'Class'
    | 'ArrayLikeOfObject'

export interface TypeBase {
    __kind?: TypeKind
    __schema?: Schema
    __coreType?: 'string' | 'number' | 'boolean' | 'Array' | 'Index' | 'object' | 'class'
    type: Types
}

export interface FieldBase extends TypeBase {
    __key: string
    __model?: ModelType
    optional?: boolean
    readonly?: boolean
    const?: boolean
    external?: boolean
    description?: string
    visible?: boolean
    default?: any
    formula?: any[]
}

export type TypesNumberLike = 'Int' | 'Timestamp' | 'Float'

export interface TypeNumberLike extends TypeBase {
    type: TypesNumberLike
    min?: number
    minExclusive?: number
    max?: number
    maxExclusive?: number
    isMs?: boolean
}

export interface FieldNumberLike extends FieldBase {
    type: TypesNumberLike
    min?: number
    minExclusive?: number
    max?: number
    maxExclusive?: number
    default?: number
    isMs?: boolean
}

export type TypesStringLike =
    | 'ID'
    | 'String'
    | 'Color'
    | 'Email'
    | 'JSON'
    | 'Phone'
    | 'URL'
    | 'IPAddress'
    | 'Date'
    | 'Time'
    | 'DateTime'
    | 'Img'

export interface TypeStringLike extends TypeBase {
    type: TypesStringLike
    category?: string
    modalPath?: string
    path?: string
    extension?: string
    size?: { width: number; height: number }
    pattern?: string
    minLength?: number
    maxLength?: number
}

export interface FieldStringLike extends FieldBase {
    type: TypesStringLike
    category?: string
    modalPath?: string
    path?: string
    extension?: string
    size?: { width: number; height: number }
    pathWithoutSize?: boolean
    previewSize?: 'Small' | 'Medium' | 'Large'
    schema?: object
    labelPath?: string
    pattern?: string
    minLength?: number
    maxLength?: number
    default?: string
    searchable?: boolean
}

export interface TypeBoolean extends TypeBase {
    type: 'Boolean'
}

export interface FieldBoolean extends FieldBase {
    type: 'Boolean'
    default?: boolean
}

export interface FieldBundle extends FieldBase {
    type: 'Bundle'
    path: string
    extension: string
    viewerLink: string
}

export type TypesArrayLike = 'List' | 'Set'

export interface FieldArrayLike extends FieldBase {
    type: TypesArrayLike
    of: Type
    minLength?: number
    maxLength?: number
    previewFields?: TypeRef[][]
    __previewFields?: Field[][]
}

export interface TypeRef extends TypeBase {
    type: 'Ref'
    $ref: string
    previewFields?: TypeRef[][]
    __previewFields?: Field[][]
    __ref: Model
}

export interface FieldRef extends FieldBase {
    type: 'Ref'
    $ref: string
    previewFields?: FieldRef[][]
    __previewFields?: Field[][]
    default?: string
    __ref: Model
    __derived: Model[]
}

export interface FieldMarkdown extends FieldBase {
    type: 'Markdown'
    imagesModalPath?: string
    imagesPath?: string
    imagesExtension?: string
}

export interface FieldIndex extends FieldBase {
    type: 'Index'
    $ref: string
    previewFields?: FieldRef[][]
    __previewFields?: Field[][]
    __ref: Index
}

export interface TypeObjectRef {
    type: string
    $ref: string
}
export interface TypeObject extends TypeBase {
    type: 'Object'
    fields: Record<string, Field>
    $refs: TypeObjectRef[] | TypeObjectRef
    previewFields?: TypeRef[][]
    __previewFields?: Field[][]
    __refs: {
        type: string
        model: Model
    }[]
}

export interface FieldObjectRef {
    type: string
    $ref: string
}
export interface FieldObject extends FieldBase {
    type: 'Object'
    fields: Record<string, Field>
    $refs: FieldObjectRef[] | FieldObjectRef
    previewFields?: TypeRef[][]
    __previewFields?: Field[][]
    __refs: {
        type: string
        model: Model
    }[]
}

export interface FieldClass extends FieldBase {
    type: 'Class'
    description?: string
    $ref: string
    previewFields?: TypeRef[][]
    __previewFields?: Field[][]
    __ref: Model
}

export interface FieldClassRef extends FieldBase {
    type: 'ClassRef'
    description?: string
    $ref: string
    previewFields?: TypeRef[][]
    __previewFields?: Field[][]
    __ref: Model
}

type RArray = (string | RArray)[]

export interface FieldFormula extends FieldBase {
    type: 'Formula'
    of: {
        type: FieldType
    }
    formula: RArray
}

export type Type = TypeNumberLike | TypeStringLike | TypeBoolean | TypeRef | TypeObject

export type Field =
    | FieldNumberLike
    | FieldStringLike
    | FieldBoolean
    | FieldBundle
    | FieldArrayLike
    | FieldRef
    | FieldMarkdown
    | FieldIndex
    | FieldFormula
    | FieldObject
    | FieldClass
    | FieldClassRef

export type FieldType = Field['type']

export interface RefField {
    $ref: string
}

export interface Index {
    __model?: ModelType
    __schema?: Schema
    __key?: string
    hash?: RefField
    range: RefField
}

export interface ModelTypeUIActions {
    new: boolean
    delete: boolean
}

export interface ModelTypeUI {
    namespace: string
    'field-title': RefField
    __field_title_ref: Field
    'field-workflow'?: RefField
    __field_workflow_ref?: Field
    actions: ModelTypeUIActions
}

export interface ModelEnum extends ModelBase {
    type: 'Enum'
    members: Record<string, ModelEnumMember>
}

export interface ModelEnumMember {
    color?: string
    final?: boolean
}

export type Model = ModelType | ModelEnum

export interface ActionTarget {
    $ref: string
    __ref: Model
    filter?: string
    group?: string
    type?: string
}

export function loadSchemaAsText(schemaText: string): Schema {
    const schema: Schema = JSON.parse(schemaText)
    for (const modelKey in schema.models) {
        if (schema.models[modelKey].type === 'Type') {
            loadModelTypeIndices(schema.models[modelKey] as ModelType, schema)
        }
    }

    for (const modelKey in schema.models) {
        loadModel(modelKey, schema.models[modelKey], schema)
    }

    for (const actionKey in schema.actions) {
        loadAction(actionKey, schema.actions[actionKey], schema)
    }

    return schema
}

function loadModel(modelKey: string, model: Model, schema: Schema) {
    model.__key = modelKey
    model.__schema = schema

    switch (model.type) {
        case 'Enum':
            // loadModelEnum(modelKey, model as ModelEnum, schema)
            break
        case 'Type':
        case 'ObjectType':
        case 'Class':
        case 'Inheritor':
            loadModelType(modelKey, model as ModelType, schema)
            break
    }
}

function loadAction(actionKey: string, actionTargets: ActionTarget[], schema: Schema) {
    for (const target of actionTargets) {
        loadActionTarget(target, schema)
    }
}

function loadActionTarget(actionTarget: ActionTarget, schema: Schema) {
    actionTarget.__ref = findRefModel(actionTarget.$ref, schema)
}

// function loadModelEnum(modelKey: string, modelEnum: ModelEnum, schema: Schema) {
//     // TODO
// }

function loadModelType(modelKey: string, modelType: ModelType, schema: Schema) {
    for (const fieldKey in modelType.fields) {
        const field = modelType.fields[fieldKey]
        if (field.type === 'Formula') {
            loadField(fieldKey, field, modelType, schema, field.of.type)
        } else {
            loadField(fieldKey, field, modelType, schema, field.type)
        }
        const ref = `#/models/${modelKey}/fields/${fieldKey}`
        if (ref === modelType.ui['field-title'].$ref) {
            modelType.ui.__field_title_ref = field
        }
        if (ref === modelType.ui['field-workflow']?.$ref) {
            modelType.ui.__field_workflow_ref = field
        }
    }
    if (modelType.inheritors) {
        modelType.inheritors.__key = modelType.fields[modelType.inheritors.key.$ref.split('/').pop()!]
        modelType.inheritors.__refs = []
        for (const ref of modelType.inheritors.$refs) {
            const model = findRefModel(ref.$ref, schema)
            modelType.inheritors.__refs.push({
                type: ref.type,
                $ref: model
            })
        }
    }
}

function loadModelTypeIndices(modelType: ModelType, schema: Schema) {
    if (modelType.indices) {
        for (const indexKey in modelType.indices) {
            loadIndex(indexKey, modelType.indices[indexKey], modelType, schema)
        }
    }
}

function loadField(fieldKey: string, field: Field, modelType: ModelType, schema: Schema, type: FieldType) {
    field.__key = fieldKey
    field.__model = modelType
    field.__schema = schema
    switch (type) {
        case 'ID':
        case 'String':
        case 'Email':
        case 'JSON':
        case 'Phone':
        case 'URL':
        case 'IPAddress':
        case 'Date':
        case 'Time':
        case 'DateTime':
        case 'Img':
        case 'Bundle':
            field.__kind = 'Scalar'
            field.__coreType = 'string'
            break

        case 'Int':
        case 'Timestamp':
        case 'Float':
            field.__kind = 'Scalar'
            field.__coreType = 'number'
            break

        case 'Boolean':
            field.__kind = 'Scalar'
            field.__coreType = 'boolean'
            break

        case 'Object':
            const fieldObject = field as FieldObject
            fieldObject.__refs = (Array.isArray(fieldObject.$refs) ? fieldObject.$refs : [fieldObject.$refs]).map(
                (ref) => ({
                    type: ref.type,
                    model: findRefModel(ref.$ref, schema)
                })
            )
            field.__kind = 'Object'
            field.__coreType = 'object'
            if (fieldObject.previewFields) {
                fieldObject.__previewFields = fieldObject.previewFields.map((fields) =>
                    fields.map((f) => {
                        const ref = f.$ref.split('/').pop()!
                        return (fieldRef.__ref as ModelType).fields[ref]
                    })
                )
            }
            break
        case 'ClassRef':
            const fieldClassRef = field as FieldClassRef
            ;(field as any).type = 'Object'
            const refClass = findRefModel(fieldClassRef.$ref, schema) as ModelType
            ;(field as any).__refs = refClass.inheritors?.$refs.map((ref) => ({
                type: ref.type,
                model: findRefModel(ref.$ref, schema)
            }))
            field.__kind = 'Object'
            field.__coreType = 'object'
            if (fieldClassRef.previewFields) {
                fieldClassRef.__previewFields = fieldClassRef.previewFields.map((fields) =>
                    fields.map((f) => {
                        const ref = f.$ref.split('/').pop()!
                        return (fieldRef.__ref as ModelType).fields[ref]
                    })
                )
            }
            break
        case 'Class':
            const fieldClass = field as FieldClass
            fieldClass.__ref = findRefModel(fieldClass.$ref, schema)
            field.__kind = 'Class'
            field.__coreType = 'class'
            break
        case 'List':
        case 'Set':
            field.__coreType = 'Array'

            const fieldArrayLike = field as FieldArrayLike
            loadType(fieldArrayLike.of, schema)

            switch (fieldArrayLike.of.__kind) {
                case 'Scalar':
                    field.__kind = 'ArrayLikeOfScalar'
                    break
                case 'RefType':
                    field.__kind = 'ArrayLikeOfRefType'
                    break
                case 'RefEnum':
                    field.__kind = 'ArrayLikeOfRefEnum'
                    break
                case 'Object':
                    field.__kind = 'ArrayLikeOfObject'
                    break
                default:
                    throw new Error(`Unsupported 'of' kind ${fieldArrayLike.of.__kind}`)
            }

            break

        case 'Ref':
            field.__coreType = 'string'
            const fieldRef = field as FieldRef
            fieldRef.__ref = findRefModel(fieldRef.$ref, schema)
            if (fieldRef.previewFields) {
                fieldRef.__previewFields = fieldRef.previewFields.map((fields) =>
                    fields.map((f) => {
                        const ref = f.$ref.split('/').pop()!
                        return (fieldRef.__ref as ModelType).fields[ref]
                    })
                )
            }
            field.__kind = fieldRef.__ref.type == 'Type' ? 'RefType' : 'RefEnum'
            break

        case 'Markdown':
            field.__kind = 'Scalar'
            field.__coreType = 'string'
            break

        case 'Index':
            field.__key = title(fieldKey)
            field.__kind = 'Index'
            field.__coreType = 'Index'
            const fieldIndex = field as FieldIndex
            fieldIndex.__ref = findRefIndex(fieldIndex.$ref, schema)
            if (fieldIndex.previewFields) {
                fieldIndex.__previewFields = fieldIndex.previewFields.map((fields) =>
                    fields.map((f) => {
                        const ref = f.$ref.split('/').pop()!
                        return fieldIndex.__ref.__model!.fields[ref]
                    })
                )
            }
            break
    }
}

function title(s?: string): string {
    return s ? s[0].toUpperCase() + s.slice(1) : ''
}

const refModelExp: RegExp = /^#\/models\/(?<name>[a-z][a-zA-Z\d]+)$/
const refIndexExp: RegExp = /^#\/models\/(?<entity>[a-z][a-zA-Z\d]+)\/indices\/(?<index>[a-z][a-zA-Z\d]+)$/

function loadType(type: Type, schema: Schema) {
    type.__schema = schema

    switch (type.type) {
        case 'ID':
        case 'String':
        case 'Email':
        case 'JSON':
        case 'Phone':
        case 'URL':
        case 'IPAddress':
        case 'Date':
        case 'Time':
        case 'DateTime':
        case 'Img':
            type.__kind = 'Scalar'
            type.__coreType = 'string'
            break

        case 'Int':
        case 'Timestamp':
        case 'Float':
            type.__kind = 'Scalar'
            type.__coreType = 'number'
            break

        case 'Boolean':
            type.__kind = 'Scalar'
            type.__coreType = 'boolean'
            break

        case 'Object':
            type.__refs = (Array.isArray(type.$refs) ? type.$refs : [type.$refs]).map((ref) => {
                const result = {
                    type: ref.type,
                    model: findRefModel(ref.$ref, schema)
                }
                if (type.previewFields) {
                    type.__previewFields = type.previewFields.map((fields) =>
                        fields.map((f) => {
                            const objRef = f.$ref.split('/').pop()!
                            return (typeRef.__ref as ModelType)!.fields[objRef]
                        })
                    )
                }
                return result
            })
            type.__kind = 'Object'
            type.__coreType = 'object'
            break

        case 'Ref':
            type.__coreType = 'string'
            const typeRef = type as TypeRef
            typeRef.__ref = findRefModel(typeRef.$ref, schema)
            if (typeRef.previewFields) {
                typeRef.__previewFields = typeRef.previewFields.map((fields) =>
                    fields.map((f) => {
                        const ref = f.$ref.split('/').pop()!
                        return (typeRef.__ref as ModelType)!.fields[ref]
                    })
                )
            }
            type.__kind = typeRef.__ref.type == 'Enum' ? 'RefEnum' : 'RefType'
            break
    }
}

function findRefModel($ref: string, schema: Schema): Model {
    const match = $ref.match(refModelExp)
    if (!match || !match.groups || !schema.models[match.groups['name']]) {
        throw new Error(`Invalid ref - ${$ref}`)
    }

    return schema.models[match.groups['name']]
}

function findRefIndex($ref: string, schema: Schema): Index {
    const match = $ref.match(refIndexExp)
    if (!match || !match.groups) {
        throw new Error(`Invalid ref - ${$ref}`)
    }

    return (schema.models[match.groups['entity']] as ModelType).indices![match.groups['index']]
}

function loadIndex(indexKey: string, index: Index, model: ModelType, schema: Schema) {
    index.__key = indexKey
    index.__model = model
    index.__schema = schema
}
