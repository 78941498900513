import React, { useCallback, useMemo } from 'react'

import Modals from '@/modals'
import { ActivePage, useCardsHelper } from '@/hooks/useCardsHelper'

import TableCard from '../TableCard'
import FormCard from '../FormCard'
import SubformCard from '../SubformCard'
import Subscription from '../Subscription'

const CardsPage: React.FC = () => {
  const { modelKey, activeCards } = useCardsHelper()

  const expandable = useMemo(() => activeCards.length > 1, [activeCards.length])
  const large = useMemo(() => activeCards.length === 1 || activeCards.some(({ page }) => page.expanded), [activeCards])

  const renderCard = useCallback(
    ({ page, active, width }: ActivePage) => {
      const card = { width, active, expandable, large }
      if (page.type === 'table' || page.type === 'index') {
        return <TableCard key={page.path} page={page} card={card} />
      }
      if (page.type === 'form') {
        return <FormCard key={page.path} page={page} card={card} />
      }
      if (page.type === 'subform') {
        return <SubformCard key={page.path} page={page} card={card} />
      }
      return null
    },
    [expandable, large]
  )

  return (
    <>
      {activeCards.map(renderCard)}
      {modelKey && <Subscription modelKey={modelKey} />}
      <Modals />
    </>
  )
}

export default CardsPage
