import { DISPLAY_SIZE_STYLES, DISPLAY_WEIGHT_STYLES } from '@/constants/styles/typography/display'
import { TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from '@/constants/styles/typography/text'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 24px 0;
    min-width: 600px;
`

export const Title = styled.div`
    ${DISPLAY_SIZE_STYLES.sm}
    ${DISPLAY_WEIGHT_STYLES.medium}
`
export const SubTitle = styled.div`
    ${TEXT_SIZE_STYLES.lg}
    ${TEXT_WEIGHT_STYLES.medium}
`

export const Fields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    .small {
        width: 70%;
        gap: 8px;
    }
`
export const FieldsContainer = styled(Fields)`
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 24px;
    margin: 24px 0;
`

export const DndFieldsRow = styled.div<{ isDragged?: boolean }>`
    display: flex;
    gap: 12px;
    background: ${({ theme }) => theme.colors.base.white};
    cursor: grab;
    ${({ theme, isDragged }) =>
        isDragged &&
        `
        cursor: grabbing;
        border-radius: 8px;
        ${theme.shadows.regular.lg}
    `}
`

export const FieldsRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
`

export const FieldContainer = styled.div<{ small?: boolean }>`
    display: flex;
    flex-direction: row;

    .icon {
        margin: auto 0;
    }
    .offset {
        margin-top: 30px;
    }

    .plus-button {
        width: 100px;
    }

    ${({ small }) =>
        !small &&
        `
        width: 100%;
        min-width: 150px;
    `}

    & > * {
        width: 100%;
    }
`

export const Buttons = styled.div<{ padding?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    ${({ padding }) => padding && 'padding: 0 24px;'}
`

export const Header = styled.div`
    display: flex;
    align-items: flex-end;
    padding: 0 24px;
`

export const HeaderLocale = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;

    .select {
        min-width: 100px;
    }
`
